<template>
  <div>
    <el-dialog class="prompt-dialog" title="" :visible.sync="showPromptVisible" width="700px"  :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <span class="prompt-title">提示</span>
        <span class="prompt-info">（工资条涉及个人隐私，请验证身份）</span>
      </div>
      <div class="dialog_content">
        <el-form label-width="45px" :rules="rules" ref="ruleForm" :model="form">
          <div class="pwd_input">
            <el-form-item label="密码" prop="input">
              <el-input v-model="form.input" placeholder="请输入密码" :type="inputtype"> </el-input>
              <en-icon class="eyes" :name="iconImg" size="small" style="color:#A8B6C6" @click.native="changeState"> </en-icon>
            </el-form-item>
          </div>
        </el-form>
        <a href="javascript:void(0)" class="forgot_pwd" @click="changePassword">忘记密码？</a>
      </div>
      <span slot="footer" class="dialog-footer">
        <en-button type="primary" @click="confirm('ruleForm')">确 定</en-button>
      </span>
    </el-dialog>
    <changePassword :show-change-password="showChangePassword" @closeChangePassword="getCloseChangePassword"></changePassword>
  </div>
</template>

<script>
import ChangePassword from "@/views/payRoll/user/prompt/changePassword";
// import { payRollApi } from "@/api/payRoll";
import { loginService } from "@/api";

export default {
  name: "Prompt",
  components: { ChangePassword },
  props: ["showPrompt", "payRollId", "uuid"],
  data() {
    const input = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      inputtype: "password",
      iconImg: "bi-denglu",
      form: {
        input: ""
      },
      rules: {
        input: [
          {
            required: true,
            validator: input,
            trigger: "blur"
          }
        ]
      },
      btnIndex: 0,
      showChangePassword: false
    };
  },
  computed: {
    showPromptVisible: {
      get() {
        return this.showPrompt;
      },
      set(v) {
        this.$emit("closePrompt", v);
      }
    }
  },
  methods: {
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.checkPayPwd();
        }
      });
    },
    async checkPayPwd() {
      const obj = { checkValue: this.form.input, appSessionId: this.uuid };
      console.log(obj);
      const resp = await loginService.loginCheckPs({ id: this.$route.query.id, name: this.form.input });
      // await payRollApi.checkPaySlip({ checkValue: this.form.input, appSessionId: this.uuid });
      if (resp.result === 0) {
        // 身份校验成功
        // this
        this.$message.error("没有查询到工资条信息");
        this.$emit("promptCallBack", true);
      } else if (resp.result === 1) {
        this.$message.error("连续输入错误三次后将锁定该工资条数据");
      } else if (resp.result === 2) {
        if (resp.data.lockStatus === "1") {
          this.$message.error("该工资条已被锁定，请联系人事！");// 该工资条已被锁定，请联系人事！
          return;
        }
        if (resp.data.status === "4") {
          this.$message.error("没有查询到工资条信息");// 已被收回
          return;
        }
        this.$emit("promptCallBack", resp.data);
      } else if (resp.result >= 3) {
        this.$message.error("该工资条数据已锁定，请联系人事！");
        this.$emit("promptCallBack", false);
      }
    },
    changeState() {
      if (this.iconImg === "bi-denglu") {
        this.iconImg = "kai-denglu";
        this.inputtype = "text";
      } else {
        this.iconImg = "bi-denglu";
        this.inputtype = "password";
      }
    },
    changePassword() {
      this.showChangePassword = true;
    },
    getCloseChangePassword(val) {
      this.showChangePassword = val;
    }
  }
};
</script>
<style lang="scss" scoped>
.prompt-dialog .el-dialog /deep/ .el-dialog__body {
  text-align: left;
}
.el-form-item__error {
  line-height: 30px !important;
  z-index: 101;
}
</style>
<style lang="scss" scoped>
.dialog_content {
  overflow: hidden;
}
.header-title {
  text-align: left;
  margin-top: 5px;
  .prompt-title {
    font-size: 14px;
    color: #333333;
  }
  .prompt-info {
    color: #b9c4d2;
    font-size: 12px;
  }
}
.pwd_input {
  position: relative;
  .el-input {
    width: 100%;
  }
  .eyes {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 7px;
  }
}
.forgot_pwd {
  text-decoration: none;
  color: #3e90fe;
  float: right;
  margin: 0 0 20px 0;
  overflow: hidden;
  font-size: 12px;
}
</style>
