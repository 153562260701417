<template>
  <div class="payrollLeft">
    <div class="payrollLeft-top">
        <div class='top-left'>
          <en-icon
          class="icon-left back-icon"
          color='#A9B5C6'
          name="fanhuishangcengji"
          size="small"
          @click.native="$router.go(-1)"
        >
      </en-icon>
          <span>工资条管理</span>
        </div>

      <router-link :to="{ path: '/payroll/importPayroll' }">
        <en-icon
          class='icon-right '
          name="tianjia"
          color="#3e90fe"
          size="small"
        >
        </en-icon>
      </router-link>
      <en-icon
        class='icon-right '
          name="tongji"
          color="#3e90fe"
          size="small"
          @click.native="$router.push({path:'/payroll/graph'})"
        >
        </en-icon>
    </div>
    <div class="payrollLeft-inner year-sidecentbar" v-en-loading='enLoading'>
      <en-file-select-board
              :data="treeData"
              node-key="id"
              highlight-current
              ref="filtmenu"
              accordion
              expand-icon="gongzi"
              collpase-icon="gongzi"
              :props="defaultProps"
              @node-click="singleClick"
              :render-content="renderContent"
            >
            </en-file-select-board>
    </div>
  </div>
</template>

<script>
import { payRollApi } from "@/api/payRoll";

export default {
  name: "PayrollLeft",
  components: {},
  data() {
    return {
      treeData: [],
      enLoading: null,
      defaultProps: {
        label: "name",
        value: "id",
        disabled: (item) => item.disabled
      }
    };
  },
  mounted() {
    this.getDataList();
  },
  activated() {
    this.getDataList();
  },
  computed: {},
  methods: {
    singleClick(data) {
      if (data.disabled) {
        // this.$emit("onLeftItemClick", { id: "", name: data.name });
      } else {
        this.$emit("onLeftItemClick", data);
      }
    },
    recallAll(event, data, type) {
      console.log(event);
      const typeStr = `${type === "1" ? "删除" : "撤回"}`;
      this.$confirm(`确认${typeStr}工资条?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        await payRollApi.paySlipBtn({ templateId: data.id, type });
        this.$message({
          type: "success",
          message: `${typeStr}成功!`
        });
        this.$parent.$refs.payrollRight.loadData();// 通知右侧payrollRight更新数据
      });
    },

    async getDataList() {
      this.enLoading = "loading";
      const resp = await payRollApi.queryPaySlipsYearList("0");
      // 洗数据，支持控件展示
      resp.yearList.forEach((element) => {
        element.mName = String(element.payYear);
      });
      this.treeData = resp.yearList.map((item) => {
        const parentNode = {
          children: [],
          id: `${item.payYear}`,
          name: `${item.payYear}年`,
          disabled: true
        };
        if (item.monthList.length) {
          parentNode.children = item.monthList.map((monthItem) => ({ id: monthItem.templateId, name: monthItem.mName }));
        }
        return parentNode;
      });
      // 默认展示第一条数据
      if (this.treeData.length > 0) {
        this.enLoading = null;
        if (this.treeData[0].children.length > 0) {
          this.$emit("onLeftItemClick", this.treeData[0].children[0]);
        }
        this.$nextTick(() => {
          this.$emit("leftLoadEnd", true);
        });
      } else {
        this.$emit("leftLoadEnd", false);
        this.enLoading = "NoData";
      }
    },
    renderContent(h, { node, data, store }) {
      console.log(h, node, data, store);
      if (data.children && data.children.length !== 0) {
        return (
          <span class='custom-tree-node'>
            <span> {node.label}</span>
          </span>);
      }
      // TODO 需要后台queryPaySlipsYearList接口中返回按钮状态才好做
      return (
          <span class='custom-tree-node'>
            <span><en-icon name='gongzi' size='10' style="margin-right:10px"></en-icon>{node.label}</span>
          </span>);
    }
  }

};
</script>
<style>
.payrollLeft .el-tree-node__content {
  padding-left: 10px !important;
  text-align: left;
}
</style>

<style lang="scss" scoped>
.payrollLeft {
  width: 305px;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  float: left;

  .payrollLeft-top {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #eee;
    line-height: 50px;
    text-align: left;
    text-indent: 20px;
    font-weight: bold;
    font-size: 14px;
    .top-left{
      text-indent: 0;
      display: inline-block;
    }
    .icon-right {
      float: right;
      margin: 15px 20px 0 0;
    }
    .icon-left{
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
    }
  }
  .year-sidecentbar {
    padding: 10px 20px 40px;
    overflow-y: auto;
    height: 100%;
    /deep/ .en-file-select-board-container .tree-node-level-1 {
        background: white;
        border: 1px solid #e8ecf2;
        border-radius: 4px;
        margin-bottom: 10px;

    }
    /deep/ .en-file-select-board-container  .tree-node-level-1.is-expanded > .el-tree-node__content {
        background-color: #3e90fe !important;
        color: white !important;
        padding-left: 10px !important;
        border-radius: 4px;
        .el-tree-node__expand-icon>.en-icon{
          color: #fff!important;
        }
        &:hover{
          .el-dropdown-link{
            color: #fff;
          }
        }
    }

    /deep/  .custom-tree-node{
            display: flex;
            align-items: center;
            justify-content:space-between;
            width: 100%;
            .label{
              flex: 1;
            }
            .btns{
              width: 30px;
              flex: 0;
              margin-top: 6px;
              color: inherit;
              visibility: hidden;
            }
          }
    /deep/ .el-tree-node__content:hover{
      .custom-tree-node .btns{
        visibility: visible;
      }
    }
  }
}
</style>
