<template>
  <div>
    <div class="PaystubRight">
      <div class="top-nav">
        <div class="top-nav-left">
          <router-link to="/payroll">
            <en-icon
              name="houtui"
              size="small"
            >
            </en-icon>
            <span>{{rName}}{{payRollDetail.mName}}</span>
          </router-link>
        </div>
        <div class="top-nav-right">
          <en-image-setting
            v-if="mode=='view'&&payRollDetail.status!==5"
            name="chehui"
            fill="#FF0000"
            @click.native="()=>{recallAll()}"
          >
            撤回
          </en-image-setting>
          <en-image-setting
            v-if="mode=='view'"
            name="bianji1"
            @click.native="()=>{switchMode('edit')}"
          >
            编辑
          </en-image-setting>
          <en-image-setting
            name="bianji1"

            fill="#67c23a"
            v-if="payRollDetail.lockStatus==='1'&&mode=='view'"
            @click.native="lockPay"
          >
            解锁
          </en-image-setting>
          <en-image-setting
            name="goutong"
            fill="#3e90fe"
            @click.native="linkup"
          >
            沟通
          </en-image-setting>
        </div>
      </div>
      <div class="info-table-wrap">
        <div class="info-table" v-en-loading='enLoading'>
          <table v-if="payRollDetail">
            <tr v-for="colItem in showLabelList" :key="colItem.label">
              <td>{{colItem}}</td>
              <td>
                <input type="text" v-if="mode==='view'" :disabled="true" :value='payRollDetail[colItem]'>
                <el-input
                  v-else
                  type="text"
                  :disabled="colItem===payRollDetail.refInfo.enAccountKey"
                  v-model="payRollDetail[colItem]"
                ></el-input>
              </td>
            </tr>
          </table>

        </div>
      </div>

      <div
        v-if="mode==='edit'"
        class="bottom-nav"
      >
          <el-checkbox
            v-model="isSendNotice"
            label="提交后重发通知"
            name="type"
          ></el-checkbox>

        <en-button @click="save">
          保存
        </en-button>
        <en-button @click="switchMode('view')" type='white'>
          取消编辑
        </en-button>
      </div>
    </div>
    <!--沟通弹窗-->
    <add-chats ref="addPerson" v-if="showaddChats"  @change="sendMessage">
    </add-chats>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { request } from "en-js";
import { payRollApi } from "@/api/payRoll";
import addChats from "@/views/chat/view/addChats";
import Utils from "@/tools/chat";
import { team } from "@/api/chat";

export default {
  name: "PaystubHr",
  components: { addChats },
  data() {
    return {
      enLoading: null,
      dataBak: null, // 编辑时备份老数据 取消编辑时回显
      mode: "view",
      showLabelList: [], // 可展示的字段
      showaddChats: false,
      payRollDetail: {},
      collist: [],
      rName: "", // 当前员工姓名
      isSendNotice: null
    };
  },
  computed: {
  },
  methods: {
     @request(true, "enLoading", {
       subTitle: "正在加载中..."
     })
    async loadData() {
      this.payRollDetail = await payRollApi.queryPaySlipsDetail(this.$route.query.id);
      this.rName = this.payRollDetail.refInfo.name;
      const noarr = [
        "isShow", "sendTime", "payment", "isSign", "mobile", "signUrl",
        "createId", "createTime", "id", "lockStatus", "mName", "payMonth",
        "payType", "payYear", "refInfo", "remark", "sendType", "status",
        "templateId", "tenantId", "tenantName"];
      const colres = await payRollApi.queryPaySlipsColList(this.payRollDetail.templateId);
      const labelList = Object.keys(this.payRollDetail).filter((item) => !noarr.includes(item));
      this.showLabelList = [];
      colres.colList && colres.colList.forEach((element) => {
        labelList.forEach((item) => {
          if (item && element.fieldName === item) {
            this.showLabelList.push(item);
          }
        });
      });
      this.mode = "view";
    },
    // 发送消息
    async sendMessage(userlist) {
      if (userlist.length === 1) { // 列表存在单聊 直接选中
        const imUserId = userlist[0].imUserId;
        const session = this.$store.state.chat.sessionlist.find((item) => item.to === imUserId);
        if (session) {
          this.$store.dispatch("chat/setCurrSession", session.id);
          this.$router.push({
            path: "/chat",
            query: { id: session.id, unread: session.unread }
          });
          return;
        }
      }
      // 先同步资料
      const accounts = userlist.map((item) => item.imUserId) || [];
      this.$store.dispatch("chat/searchUsers", { accounts });

      const res = await team.createTeam(userlist);
      const obj = {};
      obj.sessionId = Utils.getSessionId(this.$store.state.chat.userId, res.sessionId);
      obj.scene = userlist.length > 1 ? "team" : "p2p";
      this.$router.push({
        path: "/chat",
        query: { id: `${obj.scene}-${obj.sessionId}`, unread: 0 }
      });
    },
    lockPay() {
      this.$confirm("确定要解锁该工资条?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        await payRollApi.paySlipBtn({ id: this.$route.query.id, type: "3" });
        this.$message({
          type: "success",
          message: "解锁成功!"
        });
        this.loadData();
      });
    },
    switchMode(mode) {
      if (mode === "edit") {
        this.dataBak = cloneDeep(this.payRollDetail);
      } else {
        this.payRollDetail = this.dataBak ? cloneDeep(this.dataBak) : "";
      }
      this.mode = mode;
    },

    async save() {
      const updateData = {};
      const loading = this.$enLoading("正在保存中...");

      this.showLabelList.forEach((item) => {
        updateData[item] = this.payRollDetail[item];
      });
      const submitData = {
        id: this.$route.query.id,
        templateId: this.payRollDetail.templateId,
        updateData: JSON.stringify(updateData),
        isSendNotice: Number(!this.isSendNotice)
      };
      try {
        await payRollApi.updateEditPaySlip(submitData);
        this.loadData();
      } finally {
        loading.close();
      }
    },
    linkup() {
      this.showaddChats = true;
      this.$nextTick(() => {
        this.$refs.addPerson.open();
      });
    },
    getCloseLinkup(val) {
      this.showaddChats = val;
    },
    // 撤回
    recallAll() {
      const id = this.$route.query.id;
      this.$confirm("确认撤回工资条?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        await payRollApi.paySlipBtn({ id, type: 2 });
        this.$message({
          type: "success",
          message: "撤回成功!"
        });
        this.loadData();
      });
    }
  },
  watch: {
    $route: {
      handler() {
        this.loadData();
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
<style lang="scss">
.top-nav-right {
  .text {
    font-size: 14px !important;
  }
}
</style>

<style lang="scss" scoped>
#app .full-page {
  background-color: #1d2532;
  padding: 10px;
  overflow: hidden;
}
.PaystubRight {
  background: #fff;
  height: 100%;
  border-radius: 5px;
  text-align: left;
  position: relative;
  .top-nav {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #eee;
    .top-nav-left {
      float: left;
      margin: 15px 0 0 15px;
      .en-icon {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
        margin: 3px 0 0 3px;
        font-weight: 600;
        color: #333;
      }
    }
    .top-nav-right {
      float: right;
      margin: 15px 15px 0 0;
      .en-image-setting {
        margin-left: 15px;
      }
    }
  }
  .info-table-wrap {
    width: 100%;
    height: 100%;
  }
  .info-table {
    background: #fff;
    height: 100%;
    position: relative;
    overflow: auto;
    padding-bottom: 50px;
    table {
      border: 1px solid #eee;
      border-collapse: collapse;
      width: 100%;
      font-size: 14px;
      tr {
        td {
          border: 1px solid #eee;
          border-collapse: collapse;
          input {
            border: 0 none;
            background-color: #fff;
            width: 100%;
            height: 100%;
            padding: 16px 0 16px 12px;
          }
          & /deep/ .el-input__inner {
            height:48.8px
          }
        }
        td:nth-child(1) {
          color: #333;
          width: 230px;
          padding-left: 10px;
        }
        td:nth-child(2) {
          color: #666;
        }
      }
      tr:nth-child(odd),tr:nth-child(odd) td input {
        background-color: #f7f9f9;
      }
      tr:last-child {
        background-color: #fff;
      }
    }
  }
  .bottom-nav {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 65px;
    border-top: 1px solid #eee;
    background-color: #fff;
    .el-button {
      float: right;
      margin: 20px 20px 0 0;
    }
    .el-checkbox {
      margin-top: 28px;
      margin-left: 20px;
      display: inline-block;
    }
  }
}
</style>
