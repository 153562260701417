<template>
  <div class="root-layout">
    <topNav></topNav>
    <div class="paystub-right-right">
      <paystubRight v-if="id && showDetail" :id="id"  idType="payRollId"></paystubRight>
    </div>
    <prompt-index ref="promptIndex" :mode='mode'></prompt-index>
  </div>
</template>

<script>
import TopNav from "@/views/payRoll/user/paystubNav/topNav";
import PaystubRight from "@/views/payRoll/user/paystub/paystubRight";
import PromptIndex from "@/views/payRoll/user/prompt/index";
// import { loginService } from "@/api";

export default {
  name: "Paystub",
  components: {
    TopNav,
    PaystubRight,
    PromptIndex
  },
  data() {
    return {
      mode: "login", // login 为登录模式  set 为设置密码模式
      id: "",
      uuid: "",
      showPrompt: false,
      // 是否可以展示详情
      showDetail: false
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
    this.uuid = this.$route.query.uuid;
    this.initPage();
  },
  methods: {
    initPage() {
      const u = navigator.userAgent;
      const isAndroid = (u.indexOf("Android") > -1) || (u.indexOf("Linux") > -1);// android终端或者uc浏览器
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isAndroid) {
        const ifr = document.createElement("iframe");
        ifr.src = `enfry://salaryDetail?id=${this.id}`;
        ifr.style.display = "none";
        this.checkOpen((is) => {
          if (is === 0) {
            // 安装
            window.location.href = `enfry://salaryDetail?id=${this.id}`;
          } else {
            // 没有安装
            this.isPayPassword();
          }
        });
      } else if (isiOS) {
        const ifr = document.createElement("iframe");
        ifr.src = `enfry://salaryDetail?id=${this.id}`;
        ifr.style.display = "none";
        this.checkOpen((is) => {
          if (is === 0) {
            // 安装
            window.location.href = `enfry://salaryDetail?id=${this.id}`;
          } else {
            // 没有安装
            this.isPayPassword();
          }
        });
      } else {
        // pc
        this.isPayPassword();
      }
    },
    checkOpen(cb) {
      const _clickTime = +(new Date());
      // 启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
      let _count = 0;
      const intHandle = setInterval(() => {
        _count++;
        const elsTime = +(new Date()) - _clickTime;
        if (_count >= 100 || elsTime > 3000) {
          clearInterval(intHandle);
          if (elsTime > 3000 || document.hidden || document.webkitHidden) {
            cb(1);
          } else {
            cb(0);
          }
        }
      }, 20);
    },
    async isPayPassword() {
      // zjh 有已登录的 账号 为了避免下面的账号错乱 提示并跳到 index.do
      // if (this.$store.getters.tenantId) {
      //   this.$message({
      //     type: "warning",
      //     message: "已登录账户，跳转到首页！"
      //   });
      //   const jumpIndex = setTimeout(() => {
      //     this.$router.replace("/");
      //     clearTimeout(jumpIndex);
      //   }, 1000);
      //   return;
      // }
      // const resData = await loginService.loginCheckPs({ id: this.id });
      // const isPayPassword = Number(resData.isPayPassword);
      // // isPayPassword 工资条密码 是否修改密码(0未修改 1已修改）
      // if (isPayPassword === -1 || isPayPassword === 1) {
      //   //
      //   this.mode = "login";
      // } else {
      //   // 需要 弹出 首次输入密码
      //   this.mode = "set";
      // }
      this.showPrompt = true;
      // this.mode = "set";
      this.$refs.promptIndex.handlePayRoll(this.mode);
    },
    getClosePrompt(val) {
      // 工资条验证密码弹框关闭
      this.showPrompt = val;
    },
    payRollVerfyCallBack(val) {
      // 工资条验证密码弹框回调方法
      this.showPrompt = false;
      if (val) {
        this.showDetail = true;
      } else {
        // 工资条密码验证失败三次返回登录
        this.$router.push("/login");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
#app .full-page {
  background-color: #eee;
  overflow: hidden;
}
.root-layout {
  background-color: #ecf1f7;
}
.paystub-right-right {
  width: 100%;
  height: calc(100% - 60px);
  padding: 10px;
  padding-top: 0;
}
.paystub-right {
  margin-left: 0 !important;
}
</style>
