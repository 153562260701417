<template>
  <div>
    <payrollLeft @leftLoadEnd='leftLoadEnd' @onLeftItemClick='onLeftItemClick'></payrollLeft>
    <payrollRight :leftData='selectItem' ref="payrollRight"></payrollRight>
  </div>
</template>

<script>
import PayrollLeft from "@/views/payRoll/hr/payroll/payrollLeft";
import PayrollRight from "@/views/payRoll/hr/payroll/payrollRight";

export default {
  name: "Payroll",
  components: {
    PayrollLeft,
    PayrollRight
  },
  data() {
    return {
      selectItem: {}
    };
  },
  computed: {},
  methods: {
    leftLoadEnd(flag) {
      if (!flag) {
        this.$router.replace({
          path: "/payroll/paystubEmpty"
        });
      }
    },
    onLeftItemClick(item) {
      console.log(item);
      this.selectItem = item;
    }
  }
};
</script>
<style lang="scss" scoped>
#app .full-page {
  background-color: #1d2532;
  padding: 10px;
  overflow: hidden;
}
</style>
