<template>
  <div class="nav">
    <div class="logo">
      <img src="@/assets/favicon.png" alt="" />
      <span>河洛</span>
    </div>
    <button @click="back">进入河洛</button>
  </div>
</template>

<script>
export default {
  name: "TopNav",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    back() {
      this.$router.push({
        path: "/"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 0 0 5px 5px;
  background: #fff;
  .logo {
    float: left;
    margin: 15px 0 0 45px;
    img {
      width: 33px;
      height: 29px;
    }
    span {
      color: #49bef2;
      font-size: 26px;
      font-weight: bold;
      vertical-align: top;
    }
  }
  button {
    border: 0 none;
    outline: none;
    border: 1px solid #3e90fe;
    background-color: #fff;
    border-radius: 5px;
    color: #3e90fe;
    cursor: pointer;
    float: right;
    margin: 15px 45px 0 0;
  }
}
</style>
