var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav" }, [
    _vm._m(0),
    _c("button", { on: { click: _vm.back } }, [_vm._v("进入河洛")]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: require("@/assets/favicon.png"), alt: "" } }),
      _c("span", [_vm._v("河洛")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }