<template>
  <div class="checkdata-page-wrap">
    <div class="importPayroll">
      <div class="top-nav">
        <div class="top-nav-left">
            <en-icon name="houtui" size="small" @click.native='$router.go(-1)'> </en-icon>
            <span>导入工资条</span>
        </div>
        <div class="top-nav-step">
          <en-step-list :step-options="stepOptions" :current-step="stepIndex" />
        </div>
        <div class="top-nav-right">
          <en-button @click="checkData">
            下一步
          </en-button>
        </div>
        <div class="top-nav-right2">
          <en-button type="normal" @click="prevStep" >
            上一步
          </en-button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-inner">
        <div class="title">
          {{preForm&&preForm.dateStr}}{{preSelect&&preSelect.label}}-<span class='desc'>{{preForm&&preForm.desc}}</span>
          <el-tooltip class="item" effect="dark" :content="preForm.desc" v-if="preForm&&preForm.desc" placement="top-start">
            <en-icon name="tishi" color="#F7BF27" size="16"></en-icon>
          </el-tooltip>
        </div>
        <div class="table-info">
          <en-table :data="tableVal" v-if="tableCol.length">
            <en-table-column v-for="(item,index) in tableCol" :key="item.field" :prop="item.field" :label="item.name||'序号'" :data="item" :filter-sort-config="{}">
              <template v-slot:header="{ column }">
                 <el-checkbox v-model="checked[index]" @change="(v)=>{colCheckedChange(v,item,index)}">{{ column.title }}</el-checkbox>
              </template>
            </en-table-column>
          </en-table>
          <!-- <en-pagination :page-model="pageModel"></en-pagination> -->
        </div>
        <div class="field">
          <div class="title">
            识别字段
          </div>
          <el-form ref="pipeiform" :model="form" label-width="80px" :rules="rules">
            <div>
              <el-form-item style="width:45%; margin-right:30px;" label="员工姓名" prop="name">
                <en-select v-model="form.name" placeholder="请选择" @change="setChecked"  :data="colList" :props="{ label: 'name', value: 'field' }"> </en-select>
              </el-form-item>
              <el-form-item style="width:45%; margin-right:30px;" label="实发金额" prop="payment">
                <en-select v-model="form.payment" placeholder="请选择"  @change="setChecked" :data="colList" :props="{ label: 'name', value: 'field' }"> </en-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item style="width:45%; margin-right:30px;" label="河洛 账号" :prop="!isneedEmail?'enAccount':''" :key="isneedEmail+'enAccount'">
                <en-select v-model="form.enAccount" placeholder="请选择" @change="setChecked"  :data="colList" :props="{ label: 'name', value: 'field' }"> </en-select>
              </el-form-item>
              <el-form-item style="width:45%; margin-right:30px;" label="手 机 号" :prop="!isneedEmail?'mobile':''" :key="isneedEmail+'mobile'">
                <en-select v-model="form.mobile" placeholder="请选择"  @change="setChecked" :data="colList" :props="{ label: 'name', value: 'field' }"> </en-select>
              </el-form-item>
            </div>
            <el-form-item style="width:45%; margin-right:30px;" label="邮箱地址" v-if="isneedEmail" prop="邮箱地址">
              <en-select v-model="form.email" placeholder="请选择"   :data="colList" :props="{ label: 'name', value: 'field' }"> </en-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters, mapMutations } from "vuex";
import { request } from "en-js";
import { payRollApi } from "@/api/payRoll";

export default {
  name: "CheckData",
  components: {},
  data() {
    return {
      checked: [],
      stepOptions: ["上传工资表", "校验数据", "发送通知"],
      stepIndex: 2,
      notice: "",
      rules: {
        name: [
          { required: true, message: "请选择员工姓名字段", trigger: "change" }
        ],
        payment: [
          { required: true, message: "请选择实发工资字段", trigger: "change" }
        ],
        enAccount: [
          { required: true, message: "请选择河洛账号字段", trigger: "change" }
        ],
        email: [
          { required: true, message: "请选择邮箱字段", trigger: "change" }
        ],
        mobile: [
          { required: true, message: "请选择手机号码字段", trigger: "change" }
        ]
      },
      form: {
        name: "",
        payment: "",
        enAccount: "",
        email: "",
        mobile: ""
      }
    };
  },
  computed: {
    ...mapGetters("payRollModel", {
      storedFileData: "storedFileData",
      preSelect: "storedSelect",
      preForm: "storedForm"
    }),
    tableCol() { // 表头字段列表
      return this.storedFileData?.col || null;
    },
    colList() { // 去掉序号index的表头字段列表
      return this.tableCol.filter((item) => item.name);
    },
    tableVal() { // 导入的表格数据列表
      return this.storedFileData?.val || null;
    },
    isneedEmail() { // 是否邮箱方式通知
      return this.notice?.defaultValue?.method.indexOf("3") > -1 || false;
    }
  },
  created() {
    if (!this.tableCol || !this.preSelect) {
      this.$router.replace("/payroll/importPayroll");
    }
    this.checked = this.tableCol.map(() => true);
    this.queryModel();
  },
  activated() {

  },
  beforeRouteEnter(to, from, next) {
    // ...
    console.log(from.name);
    if (from.name === "sendNote") {
      to.meta.keepAlive = true;
    } else {
      to.meta.keepAlive = false;
    }
    next();
  },
  methods: {
    ...mapMutations("payRollModel", ["saveCheckData", "saveRefInfo"]),
    // 获取通知模型
    async queryModel() {
      const res = await payRollApi.imQueryModel({ refType: "99" });
      if (res.length === 0) {
        this.$message.error("未设置通知模型，请联系管理员设置");
      } else {
        this.notice = res[0];
      }
      this.initMatchedField();
    },
    diffField(list) {
      const len = list.length;
      let result = "";
      for (let index = 0; index < len; index++) {
        const item = list[index];
        for (let i = 0; i < this.colList.length; i++) {
          const colItem = this.colList[i];
          if (colItem.name.indexOf(item) > -1) {
            result = colItem.field;
            console.log(result);
            return result;
          }
        }
      }
      return result;
    },
    /**
     * @description: 检测数据时，需识别出手机号码，邮箱地址，员工姓名，实发金额，河洛账号字段，未识别出对应值，得显示空值 规则根据字段中文来匹配
     * @param {*}
     * @return {*}
     */
    initMatchedField() {
      const namReg = ["姓名", "名字", "name"];// 员工姓名可能匹配到的字段或包含字段
      const paymentReg = ["实发工资", "实发金额", "实发", "工资金额", "工资", "补贴", "绩效", "补发", "报销"];// 实发工资可能匹配到的字段
      const enReg = ["河洛账号", "河洛", "en", "账号", "手机号", "手机", "联系方式", "phone", "mobile_phone"];// 河洛账号可能匹配到的字段
      const emailReg = ["邮箱地址", "邮箱", "email"];// 邮箱可能匹配到的字段
      const phoneReg = ["手机号", "手机", "联系方式", "phone", "mobile_phone"];
      this.form.name = this.diffField(namReg);
      this.form.payment = this.diffField(paymentReg);
      this.form.enAccount = this.diffField(enReg);
      this.form.email = this.diffField(emailReg);
      this.form.mobile = this.diffField(phoneReg);
    },
    // 表头选择框事件
    colCheckedChange(v, col, index) {
      if (!v) {
        const hasSelect = Object.keys(this.form).some((item) => this.form[`${item}`] === col.field);
        if (hasSelect) {
          this.$message.error("已被识别字段选择不能进行改操作");
          this.$set(this.checked, index, true);
        }
      }
    },
    // 监听识别字段选择 设置表头的选择状态
    setChecked(v) {
      const index = this.tableCol.findIndex((item) => item.field === v);
      console.log(index);
      this.$set(this.checked, index, true);
      this.checked[index] = true;
    },
    @request(true, "loading")
    checkData() {
      this.$refs.pipeiform.validate(async(valid) => {
        if (valid) {
          this.checked.forEach((colItem, ind) => {
            this.tableCol[ind].isShow = Number(!colItem);
          });
          // 接口请求
          const res = await payRollApi.importPaySlipsCheck(this.form, this.tableCol, this.tableVal, this.storedFileData.count);
          // TODO本地目前返回手机号不存在，后续需在服务器上调试解析数据并处理跳转
          console.log(res);
          if (res.error) {
            this.$message.error(res.msg);
            return;
          }
          this.saveCheckData(res);
          this.saveRefInfo(this.form);
          this.nextStep();
          return;
        }
        console.log("error submit!!");
      });
    },
    // 页面刷新
    nextStep() {
      this.$router.push({ path: "/payroll/sendNote" });
    },
    prevStep() {
      this.$router.push({ path: "/payroll/importPayroll" });
    }
  }

};
</script>
<style lang="scss">
.checkdata-page-wrap {
  .el-input__icon {
    line-height: 35px !important;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 600px;
  }
}
</style>

<style lang="scss" scoped>
#app .full-page {
  background-color: #eee;
  overflow: hidden;
}
.importPayroll {
  float: left;
  width: 100%;
  .top-nav {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    .top-nav-left {
      float: left;
      margin: 20px 0 0 20px;
      .en-icon {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
        margin: 3px 0 0 3px;
        font-weight: 600;
        color: #333;
      }
    }
    .top-nav-step {
      display: flex;
      height: 100%;
      width: 80%;
      justify-content: center;
      align-items: center;
    }
    .top-nav-right {
      float: right;
      margin-top: -45px;
      margin-right: 15px;
    }
    .top-nav-right2 {
      float: right;
      margin-top: -45px;
      margin-right: 105px;
    }
  }
}
.content {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #eee;
  padding-top: 70px;
  .content-inner {
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    text-align: left;
    position: relative;
    .title {
      font-weight: 600;
      font-size: 14px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #eee;
      line-height: 50px;
      padding-left: 30px;
      .desc{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100px;
        display: inline-block;
        vertical-align: bottom;
      }
      .en-icon {
        margin: 16px 0 0 5px;
        vertical-align: top;
      }
    }
    .table-info {
      margin-top: 10px;
    }
    .field {
      border-top: 1px solid #ddd;
      .title {
        font-weight: 600;
        font-size: 14px;
        width: 100%;
        height: 50px;
        line-height: 50px;
        border-bottom: 0 none;
        padding-left: 20px;
      }
      .el-form-item {
        display: inline-block;
      }
      .en-select {
        width: 100%;
      }
    }
  }
}
</style>
