var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "PaystubRight" }, [
        _c("div", { staticClass: "top-nav" }, [
          _c(
            "div",
            { staticClass: "top-nav-left" },
            [
              _c(
                "router-link",
                { attrs: { to: "/payroll" } },
                [
                  _c("en-icon", { attrs: { name: "houtui", size: "small" } }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.rName) + _vm._s(_vm.payRollDetail.mName)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "top-nav-right" },
            [
              _vm.mode == "view" && _vm.payRollDetail.status !== 5
                ? _c(
                    "en-image-setting",
                    {
                      attrs: { name: "chehui", fill: "#FF0000" },
                      nativeOn: {
                        click: function ($event) {
                          return (() => {
                            _vm.recallAll()
                          }).apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" 撤回 ")]
                  )
                : _vm._e(),
              _vm.mode == "view"
                ? _c(
                    "en-image-setting",
                    {
                      attrs: { name: "bianji1" },
                      nativeOn: {
                        click: function ($event) {
                          return (() => {
                            _vm.switchMode("edit")
                          }).apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" 编辑 ")]
                  )
                : _vm._e(),
              _vm.payRollDetail.lockStatus === "1" && _vm.mode == "view"
                ? _c(
                    "en-image-setting",
                    {
                      attrs: { name: "bianji1", fill: "#67c23a" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.lockPay.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" 解锁 ")]
                  )
                : _vm._e(),
              _c(
                "en-image-setting",
                {
                  attrs: { name: "goutong", fill: "#3e90fe" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.linkup.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" 沟通 ")]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "info-table-wrap" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.enLoading,
                  expression: "enLoading",
                },
              ],
              staticClass: "info-table",
            },
            [
              _vm.payRollDetail
                ? _c(
                    "table",
                    _vm._l(_vm.showLabelList, function (colItem) {
                      return _c("tr", { key: colItem.label }, [
                        _c("td", [_vm._v(_vm._s(colItem))]),
                        _c(
                          "td",
                          [
                            _vm.mode === "view"
                              ? _c("input", {
                                  attrs: { type: "text", disabled: true },
                                  domProps: {
                                    value: _vm.payRollDetail[colItem],
                                  },
                                })
                              : _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    disabled:
                                      colItem ===
                                      _vm.payRollDetail.refInfo.enAccountKey,
                                  },
                                  model: {
                                    value: _vm.payRollDetail[colItem],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.payRollDetail, colItem, $$v)
                                    },
                                    expression: "payRollDetail[colItem]",
                                  },
                                }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _vm.mode === "edit"
          ? _c(
              "div",
              { staticClass: "bottom-nav" },
              [
                _c("el-checkbox", {
                  attrs: { label: "提交后重发通知", name: "type" },
                  model: {
                    value: _vm.isSendNotice,
                    callback: function ($$v) {
                      _vm.isSendNotice = $$v
                    },
                    expression: "isSendNotice",
                  },
                }),
                _c("en-button", { on: { click: _vm.save } }, [
                  _vm._v(" 保存 "),
                ]),
                _c(
                  "en-button",
                  {
                    attrs: { type: "white" },
                    on: {
                      click: function ($event) {
                        return _vm.switchMode("view")
                      },
                    },
                  },
                  [_vm._v(" 取消编辑 ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.showaddChats
        ? _c("add-chats", { ref: "addPerson", on: { change: _vm.sendMessage } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }