/*
 * @Author: Zhouql
 * @Date: 2020-07-09 15:19:25
 * @LastEditTime: 2021-09-13 12:09:30
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\mixins\myFolder\typeCommon.js
 */

// 文件类型
const fileTypeList = [
  {
    value: "1", text: "gif", iconName: "fujian-tupian", iconColor: "#f7bf27"
  },
  {
    value: "2", text: "jpg", iconName: "fujian-tupian", iconColor: "#f7bf27"
  },
  {
    value: "3", text: "jpeg", iconName: "fujian-tupian", iconColor: "#f7bf27"
  },
  {
    value: "4", text: "bmp", iconName: "fujian-tupian", iconColor: "#f7bf27"
  },
  {
    value: "5", text: "png", iconName: "fujian-tupian", iconColor: "#f7bf27"
  },
  {
    value: "6", text: "pdf", iconName: "fujian-pdf", iconColor: "#f76b6e"
  },
  {
    value: "7", text: "doc", iconName: "fujian-Word", iconColor: "#4794e2"
  },
  {
    value: "8", text: "txt", iconName: "fujian-txt", iconColor: "#4794e2"
  },
  {
    value: "9", text: "xls", iconName: "fujian-excel", iconColor: "#57cc7d"
  },
  {
    value: "10", text: "xlsx", iconName: "fujian-excel", iconColor: "#57cc7d"
  },
  {
    value: "11", text: "ppt", iconName: "fujian-PPT", iconColor: "#f7c028"
  },
  {
    value: "12", text: "pptx", iconName: "fujian-PPT", iconColor: "#f7c028"
  },
  {
    value: "13", text: "vsd", iconName: "fujian-qita", iconColor: "#f7bf27"
  },
  {
    value: "14", text: "wps", iconName: "fujian-qita", iconColor: "#f7bf27"
  },
  {
    value: "15", text: "rar", iconName: "fujian-yasuobao", iconColor: "#4595de"
  },
  {
    value: "16", text: "zip", iconName: "fujian-yasuobao", iconColor: "#4595de"
  },
  {
    value: "17", text: "tar", iconName: "fujian-yasuobao", iconColor: "#4595de"
  },
  {
    value: "18", text: "gzip", iconName: "fujian-yasuobao", iconColor: "#4595de"
  },
  {
    value: "19", text: "docx", iconName: "fujian-Word", iconColor: "#4794e2"
  }
];
// 触发方式
const triggerSetList = [
  { value: 1, text: "提交时同步" },
  { value: 2, text: "流程结束时同步" }
];
/**
 * @description 获取文件类型
 * @return fileTypeList
 */
function getFileTypeList() {
  return fileTypeList;
}
/**
 * @description 获取触发方法
 * @return triggerSetList
 */
function getTriggerSetList() {
  return triggerSetList;
}

export {
  getFileTypeList,
  getTriggerSetList
};
