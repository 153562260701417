var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "import-page-wrap" }, [
    _c("div", { staticClass: "importPayroll" }, [
      _c("div", { staticClass: "top-nav" }, [
        _c(
          "div",
          { staticClass: "top-nav-left" },
          [
            _c("en-icon", {
              attrs: { name: "houtui", size: "small" },
              nativeOn: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            }),
            _c("span", [_vm._v("导入工资条")]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "top-nav-step" },
          [
            _c("en-step-list", {
              attrs: {
                "step-options": _vm.stepOptions,
                "current-step": _vm.stepIndex,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "top-nav-right" },
          [
            _c(
              "en-button",
              {
                attrs: { disabled: _vm.uploadStatus !== "finish" },
                on: { click: _vm.nextStep },
              },
              [_vm._v(" 下一步 ")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "content-inner" },
        [
          _c(
            "el-form",
            { ref: "payform", attrs: { model: _vm.form, inline: true } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "divide-item",
                  attrs: { "label-width": "80px", label: "薪资类型" },
                },
                [
                  _c("en-select", {
                    staticClass: "full-item",
                    attrs: {
                      placeholder: "请选择",
                      "data-mode": "data",
                      "value-key": "value",
                      data: _vm.currentType,
                    },
                    model: {
                      value: _vm.select,
                      callback: function ($$v) {
                        _vm.select = $$v
                      },
                      expression: "select",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "divide-item",
                  attrs: {
                    "label-width": "80px",
                    label: "薪资日期",
                    prop: "date",
                    rules: [
                      {
                        required: true,
                        message: "请选择日期",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("en-date-picker", {
                    staticClass: "full-item",
                    attrs: {
                      format: "yyyy 年 MM 月",
                      "value-format": "yyyy-MM",
                      "picker-options": _vm.pickerOptions,
                      type: "month",
                      placeholder: "选择年月",
                    },
                    on: { change: _vm.dateChange },
                    model: {
                      value: _vm.form.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "payroll-remark",
                  attrs: { label: "薪资备注", "label-width": "80px" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "upload_content" },
            [
              !_vm.uploadFile
                ? _c("img", {
                    staticClass: "img",
                    attrs: {
                      src: require("@/assets/gozi-shangchuan.png"),
                      alt: "",
                    },
                  })
                : _vm._e(),
              !_vm.uploadFile
                ? _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload_lay",
                      attrs: {
                        action: _vm.uploadUrl + "/importPaySlips",
                        data: _vm.uploadParams,
                        name: "importFile",
                        accept: ".xlsx,.xls",
                        "show-file-list": false,
                        headers: _vm.header,
                        "on-success": _vm.handleSuccess,
                        "on-error": _vm.handleError,
                        "on-progress": _vm.handleProgress,
                      },
                    },
                    [
                      _c(
                        "en-button",
                        {
                          staticClass: "upload_btn",
                          attrs: { type: "success", size: "small" },
                        },
                        [_vm._v(" 立即上传工资表 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.uploadFile
                ? _c(
                    "div",
                    { staticClass: "upload-process" },
                    [
                      _c("en-upload-progress", {
                        attrs: {
                          iconColor: _vm.suffix(_vm.uploadFile.name).iconColor,
                          iconName: _vm.suffix(_vm.uploadFile.name).iconName,
                          fileName: _vm.uploadFile.name,
                          "progress-status": _vm.uploadStatus,
                          percent: _vm.uploadFile.percentage,
                        },
                      }),
                      _c("i", {
                        staticClass: "el-icon-error delete-upload",
                        on: {
                          click: function ($event) {
                            _vm.uploadFile = null
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }