var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payroll-empty-wrap" }, [
    _c("div", { staticClass: "top-nav" }, [
      _c(
        "div",
        { staticClass: "back" },
        [
          _c("en-icon", {
            attrs: { name: "houtui", size: "20" },
            nativeOn: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
          _vm._v(" "),
          _c("span", [_vm._v("工资条管理")]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "empty-wrap" }, [
      _c("div", { staticClass: "empty" }, [
        _c("img", {
          attrs: { src: require("@/assets/notimport.png"), alt: "" },
        }),
        _c("p", [_vm._v("您还未导入工资条哦~")]),
        _c(
          "p",
          [
            _vm._v("请点击 “"),
            _c(
              "router-link",
              { attrs: { tag: "a", to: "/payroll/importPayroll" } },
              [_vm._v("添加")]
            ),
            _vm._v("” 进行导入"),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }