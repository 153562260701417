var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("payrollLeft", {
        on: {
          leftLoadEnd: _vm.leftLoadEnd,
          onLeftItemClick: _vm.onLeftItemClick,
        },
      }),
      _c("payrollRight", {
        ref: "payrollRight",
        attrs: { leftData: _vm.selectItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }