var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "change-pwd-dialog2",
      attrs: {
        title: "",
        visible: _vm.showChangePasswordTwo,
        width: "700px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showChangePasswordTwo = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "header-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_c("span", { staticClass: "prompt-title" }, [_vm._v("修改密码")])]
      ),
      _c(
        "div",
        { staticClass: "dialog_content" },
        [
          _c("div", { staticClass: "progress" }, [
            _c("div", { staticClass: "progres_number progress_dark" }, [
              _c("i", [_vm._v("1")]),
              _c("span", [_vm._v("验证身份")]),
            ]),
            _c("div", { staticClass: "progress-icon" }, [_vm._v(" —— ")]),
            _c("div", { staticClass: "progres_number progress_light" }, [
              _c("i", [_vm._v("2")]),
              _c("span", [_vm._v("修改密码")]),
            ]),
          ]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                "label-width": "70px",
                rules: _vm.rules,
                model: _vm.form,
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入新密码，8-20位字母、数字组成" },
                model: {
                  value: _vm.form.newPwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "newPwd", $$v)
                  },
                  expression: "form.newPwd",
                },
              }),
              _c("el-input", {
                attrs: { placeholder: "请确认新密码" },
                model: {
                  value: _vm.form.confirmNewPwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "confirmNewPwd", $$v)
                  },
                  expression: "form.confirmNewPwd",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "en-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.confirm("ruleForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }