var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("prompt", {
        attrs: { "show-prompt": _vm.showPrompt },
        on: {
          closePrompt: _vm.getClosePrompt,
          promptCallBack: _vm.payRollVerfyCallBack,
        },
      }),
      _c("setPassword", {
        attrs: { "show-set-password": _vm.showSetPassword },
        on: { closeSetPassword: _vm.getCloseSetPassword },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }