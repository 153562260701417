<template>
  <div>
    <button @click="prompt">
      提示弹窗
    </button>
    <prompt
      :show-prompt="showPrompt"
      @closePrompt="getClosePrompt"
    ></prompt>
  </div>
</template>

<script>
import Prompt from "@/views/payRoll/hr/prompt/prompt";

export default {
  name: "PromptHrIndex",
  components: { Prompt },
  data() {
    return { showPrompt: false };
  },
  computed: {},
  methods: {
    prompt() {
      this.showPrompt = true;
    },
    getClosePrompt(val) {
      this.showPrompt = val;
    }
  }
};
</script>
