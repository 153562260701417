<template>
  <div>
    <prompt :show-prompt="showPrompt"  @closePrompt="getClosePrompt" @promptCallBack="payRollVerfyCallBack"></prompt>
    <setPassword :show-set-password="showSetPassword" @closeSetPassword="getCloseSetPassword"></setPassword>
  </div>
</template>

<script>
import Prompt from "@/views/payRoll/user/prompt/prompt";
import SetPassword from "@/views/payRoll/user/prompt/setPassword";

export default {
  name: "PromptIndex",
  components: {
    Prompt,
    SetPassword
  },
  props: ["payRollPath"],
  data() {
    return {
      showPrompt: false,
      showSetPassword: false
    };
  },

  computed: {},
  methods: {
    payRollVerfyCallBack(val) {
      // 工资条验证密码弹框回调方法
      this.showPrompt = false;
      if (val) {
        // 工资条密码验证成功登录
        this.$router.push(this.payRollPath);
      } else {
        // 工资条密码验证失败三次返回登录
        this.$router.push("/login");
      }
    },
    getClosePrompt(val) {
      // 工资条验证密码弹框关闭
      this.showPrompt = val;
    },
    getCloseSetPassword(val) {
      // 工资条设置密码弹框关闭
      if (val) {
        // 如果是保存密码后关闭则弹出密码验证框
        this.showPrompt = true;
      }
      this.showSetPassword = false;
    },
    handlePayRoll(mode) {
      // 如果是工资条item点击要做逻辑处理，判断是否有设置密码，判断是否校验密码正确
      if (mode === "set") {
        this.showSetPassword = true;
      } else {
        this.showPrompt = true;
      }
    }
  }
};
</script>
