<template>
  <el-dialog
    class="promptDialog"
    title=""
    :visible.sync="showPrompt"
    width="700px"
    :before-close="handleClose"
  >
    <div
      slot="title"
      class="header-title"
    >
      <span class="prompt-title">提示</span>
      <span class="prompt-info">（工资条涉及个人隐私，请验证身份）</span>
    </div>
    <div class="dialog_content">
      <div class="pwd_input">
        <span>密码</span>
        <el-input
          v-model="input"
          placeholder="请输入密码"
          :type="inputtype"
        >
        </el-input>
        <en-icon
          :name="iconImg"
          size="small"
          @click.native="changeState"
        >
        </en-icon>
      </div>
      <a
        href="javascript:void(0)"
        class="forgot_pwd"
      >忘记密码？</a>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <en-button
        type="primary"
        @click="confirm"
      >确 定</en-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "Prompt",
  components: {},
  props: ["showPrompt"],
  data() {
    return {
      closePrompt: false,
      inputtype: "text",
      iconImg: "kai-denglu",
      input: "",
      btnIndex: 0
    };
  },
  computed: {},
  methods: {
    handleClose() {
      this.$emit("closePrompt", this.closePrompt);
    },
    confirm() {
      this.btnIndex++;
      console.log(this.btnIndex);
      if (this.btnIndex >= 3) {
        this.$message({
          showClose: false,
          message: "连续输入错误密码三次后将锁定该条工资条数据",
          type: "error"
        });
        this.$emit("closePrompt", this.closePrompt);
      }
    },
    changeState() {
      if (this.iconImg === "bi-denglu") {
        this.iconImg = "kai-denglu";
        this.inputtype = "text";
      } else {
        this.iconImg = "bi-denglu";
        this.inputtype = "password";
      }
    }
  }
};
</script>
<style lang="scss">
.promptDialog .el-dialog /deep/ .el-dialog__body {
  text-align: left;
}
</style>
<style lang="scss" scoped>
.dialog_content {
  overflow: hidden;
}
.header-title {
  text-align: left;
  margin-top: 5px;
  .prompt-title {
    font-size: 16px;
  }
  .prompt-info {
    color: #a9b5c6;
  }
}
.pwd_input {
  position: relative;
  .el-input {
    width: 94%;
    margin-top: 20px;
  }
  .en-icon {
    cursor: pointer;
    position: absolute;
    top: 27px;
    right: 10px;
  }
}
.forgot_pwd {
  text-decoration: none;
  color: #3e90fe;
  float: right;
  margin: 20px 0 20px 0;
  overflow: hidden;
}
</style>
