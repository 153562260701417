<template>
  <div class="payrollRight">
    <div class="top-nav">
      <div class="top-nav-left">
        <span>{{leftData.name}}</span>
      </div>
      <div class="top-nav-right">
        <en-image-setting
          name="chehui"
          fill="#FF0000"
          @click.native="()=>{recallAll()}"
        >
          撤回
        </en-image-setting>
        <en-image-setting
          name="daochu-danchuang"
          fill="#73D9B9"
          @click.native='handlExport'
        >
          导出
        </en-image-setting>
      </div>
    </div>
    <div class="user-wrap">

      <div class="info-table">

        <en-table :data="tableData"
                  ref='tablexx'
                  v-en-loading="enLoading"
                  :select-config="{checkMethod,checkDisabledMethod,checkAll:false}"
                  @select-change="handleSelectChange"
                  @filter-sort-change="handleFilterSortChange" :height-config="{ bottomHeight: 15 }" >
              <en-table-column type="index-selection" width="50"></en-table-column>
              <template v-for="(colItem,colIndex) in colList">

                <en-table-column
                  v-if="colItem.fieldName!==''"
                  :filter-sort-config="{filterable:true, sortable:true}"
                  :filter-render="{name: 'ElInput', attrs: {placeholder: '请输入过滤词...'}}"
                  :title="colItem.fieldName"
                  :prop="colItem.fieldName"
                  :data="colItem"
                    :key="colIndex"></en-table-column>
              </template>
              <en-table-column  title="状态"    width="120"  >
                <template slot-scope="scope">
                  <div>
                    <el-tag size="small" :type="statusType(scope.row.status)">{{scope.row.status|statusFilter}}</el-tag>

                  </div>
                </template>
              </en-table-column>
              <template #hoverRow="{row, rowIndex}">
                <en-expand-buttons :data="filterButtonsByStatus(row.status)" @button-click="handleButtonClick(row, rowIndex, arguments[1], arguments[0])"  />
              </template>
            </en-table>
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div class="dialog_content">
        <en-icon
          name="tishi"
          size="30"
          color="#F76B6B"
        ></en-icon>
        <span>确定撤回所选的工资条吗</span>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <en-button
          type="danger"
          @click="dialogVisible = false"
        >确 定</en-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "en-js";
import { payRollApi } from "@/api/payRoll";
import { downFile } from "@/tools/util";

export default {
  name: "PayrollRight",
  components: {},
  data() {
    return {
      filterData: [], // 过滤条件
      sortData: {}, // 排序条件
      colList: [], // 表头数据
      checkResult: [], // 已选数据
      dialogVisible: false,
      hasCheck: 0,
      weidu: "未读",
      isSelect: false,
      isItemEmpty: true,
      isThisItemEmpty: true,
      tableData: [],
      enLoading: null,
      buttonDataWithoutName: [
        {
          icon: "chehui-liebiao",
          iconColor: "#F76B6B",
          id: "ch",
          name: "撤回"
        }, {
          icon: "bianji-liebiao",
          iconColor: "#26C393",
          id: "bj",
          name: "编辑"
        }
      ]
    };
  },
  filters: {
    statusFilter(s) {
      return ["未发送", "未读", "已读", "已撤回", "已签收"][s - 1];// 1未发送，2未读，3已读，4已撤回，5已签收
    }
  },
  props: {
    leftData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {},
  methods: {
    statusType(s) {
       return ["warning", "", "info", "danger", "success"][s - 1];// 1未发送，2未读，3已读，4已撤回，5已签收
    },
    openDetails() {
      this.$router.push({ path: "/paystubHr" });
    },
    filterButtonsByStatus(status) {
      const s = Number(status);
      if ([1, 4].includes(s)) { // 未发送  已撤回只可以编辑
        return [this.buttonDataWithoutName[1]];
      }
      if (s === 3) { // 已读状态仅可以撤回
        return [this.buttonDataWithoutName[0]];
      }
      if (s === 2) { // 未读状态仅可以撤回和编辑
        return this.buttonDataWithoutName;
      }
      return [];
    },
    checkDisabledMethod({ row }) { // 已签收的不允许选中撤回
      return row.status === 5;
    },
    checkMethod({ row }) {
      return row.status !== 5;
    },
    handleSelectChange({ selection }) {
      this.checkResult = selection;
    },
    handleFilterSortChange({
      column, data, filterData, sortData
    }) {
      console.log({
        column, data, filterData, sortData
      });
      this.sortData = sortData;
      this.filterData = filterData ? JSON.parse(filterData) : [];
      this.loadTableData();
    },
    @request(true, "enLoading", {
      subTitle: "正在加载中..."
    })
    async loadTableData() {
      const dataResp = await payRollApi.queryPaySlipList(this.leftData.id, this.sortData, this.filterData);
      this.tableData = dataResp;
      setTimeout(() => {
        this.$refs.tablexx.setAllSelection(false);
      }, 0);
    },
    async loadData() {
      if (this.leftData.id) {
        const resp = await payRollApi.queryPaySlipsColList(this.leftData.id);
        // 工资条的表头字段集合
        this.colList = resp.colList;
        this.$nextTick(() => {
          this.loadTableData();
        });
      }
    },
    /**
     * @description 表格行按钮点击
     * @param {row, index, btnData, btnIndex}
     * @return:
     */
    handleButtonClick(row, index, btnData) {
      // 撤回
      if (btnData.id === "ch") {
        if (Number(row.status) === 4) {
          this.$message.error("已撤回");
        } else {
          this.recallAll(row.id);
        }
      }
      // 编辑
      if (btnData.id === "bj") {
        this.$router.push({
          path: "/payroll/paystubHr",
          query: {
            id: row.id
          }
        });
      }
    },
    // 导出
    async handlExport() {
      const res = await payRollApi.exportPaySlips({ type: 0, templateId: this.leftData.id });
      const fileName = res.headers["content-disposition"].split("filename=").pop();
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      downFile(blob, decodeURI(fileName));
      this.$message({
        type: "success",
        message: "下载成功！"
      });
    },
    recallAll(id) {
      console.log(id);
      const recalIds = id || this.checkResult.map((item) => item.id).join(",");
      if (!recalIds) {
        this.$message.error("请选择需要撤回的工资条");
        return;
      }
      this.$confirm("确认撤回选择的工资条?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        await payRollApi.paySlipBtn({ id: recalIds, type: 2 });
        this.$message({
          type: "success",
          message: "撤回成功!"
        });
        this.loadData();
      });
    },
    empty() {
      this.isItemEmpty = false;
    },
    emptyThisPhone() {
      this.isThisItemEmpty = false;
    },
    buttonClick(index) {
      if (index === 0) {
        this.dialogVisible = true;
      }
    }
  },
  watch: {
    leftData: {
      handler() {
        this.loadData();
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
<style lang="scss">
.payrollRight {
  .top-nav-right {
    .text {
      font-size: 14px !important;
    }
  }
  .image-and-text {
    display: inline-block !important;
    margin-right: 5px;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
.payrollRight {
  background: #fff;
  margin-left: 315px;
  height: 100%;
  border-radius: 5px;
  text-align: left;
  position: relative;
  padding-bottom: 110px;
  .top-nav {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #eee;
    .top-nav-left {
      float: left;
      margin: 15px 0 0 20px;
      .en-icon {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 14px;
        display: inline-block;
        vertical-align: top;
        margin: 3px 0 0 3px;
        font-weight: 600;
      }
    }
    .top-nav-right {
      float: right;
      margin: 15px 15px 0 0;
      .en-image-setting {
        margin-left: 20px;
      }
    }
  }
  .user-wrap {
    width: 100%;
    .user-nav {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #eee;
      background-color: #f7f9f9;
      font-size: 14px;
      overflow: hidden;
      .item_wrap {
        display: inline-block;
        .item {
          display: inline-block;
          border: 1px solid #eee;
          border-radius: 5px;
          margin: 10px 10px 0 10px;
          background-color: #f5f8fc;
          padding: 5px;
          .en-icon {
            margin: -1px 0 0 10px;
            vertical-align: top;
          }
        }
      }

      .clear {
        text-decoration: none;
        color: #3e90fe;
      }
    }
    .info-table {
      .weidu {
        background-color: #e2f3ef;
        color: #26c393;
        border-color: #e2f3ef;
      }
      .yidu {
        background-color: #e8ebec;
        color: #666;
        border-color: #e8ebec;
      }
      .chehui {
        background-color: #e8ebec;
        color: #666;
        border-color: #e8ebec;
      }
      .chehui {
        background-color: #f7ebeb;
        color: #f76b6b;
        border-color: #f7ebeb;
      }
      .fasong {
        background-color: #fff7e9;
        color: #ffc16e;
        border-color: #fff7e9;
      }
      .operation {
        float: right;
        margin-top: 5px;
      }
    }
    .bottom-nav {
      width: 100%;
      height: 65px;
      position: absolute;
      bottom: 15px;
      right: 20px;
    }
  }
}
.dialog_content {
  span {
    font-size: 16px;
    font-weight: bold;
    vertical-align: top;
    display: inline-block;
    margin: 10px 0 0 10px;
  }
}
</style>
