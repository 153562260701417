<template>
  <div>
    <el-dialog class="change-pwd-dialog" title="" :visible.sync="showChangePassword" width="700px" :before-close="handleClose">
      <div slot="title" class="header-title">
        <span class="prompt-title">修改密码</span>
      </div>
      <div class="dialog_content">
        <div class="progress">
          <div class="progres_number progress_light"><i>1</i><span>验证身份</span></div>
          <div class="progress-icon">
            ——
          </div>
          <div class="progres_number progress_dark"><i>2</i><span>修改密码</span></div>
        </div>
        <el-input class='mobile-input' v-model="mobileNo" placeholder="请输入手机号" > </el-input>
        <div class="input_pwd">
          <el-input v-model="form.validCode" placeholder="请输入验证码"></el-input>
          <en-button v-if="isverifi" @click="sendsms">
            获取验证码
          </en-button>
          <en-button v-else>
            {{ time }}
          </en-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <en-button type="primary" @click="nextStep">下一步</en-button>
      </span>
    </el-dialog>
    <changePassword2 :show-change-password-two="showChangePasswordTwo" @closeChangePasswordTwo="getCloseChangePasswordTwo"></changePassword2>
  </div>
</template>

<script>
import { hideCardNo } from "en-js";
import { payRollApi } from "@/api/payRoll";
import ChangePassword2 from "@/views/payRoll/user/prompt/changePassword2";

export default {
  components: { ChangePassword2 },
  props: ["showChangePassword"],
  data() {
    return {
      options: [
        {
          value: this.$store.getters.userInfo.mobileNo,
          label: hideCardNo(this.$store.getters.userInfo.mobileNo, 3)
        }
      ],
      form: { validCode: "" },
      isverifi: true, // 是否可以发送验证码
      time: 60,
      closeChangePassword: false,
      mobileNo: "",
      // 下一步之后的弹窗
      showChangePasswordTwo: false
    };
  },
  computed: {},
  methods: {
    handleClose() {
      this.$emit("closeChangePassword", this.closeChangePassword);
    },
    async nextStep() {
      if (this.form.validCode) {
        await payRollApi.checkCode(this.mobileNo, this.form.validCode);
        this.$emit("closeChangePassword", this.closeChangePassword);
        this.showChangePasswordTwo = true;
      } else {
        this.$message({
          showClose: false,
          message: "请输入验证码",
          type: "error"
        });
      }
    },
    getCloseChangePasswordTwo(val) {
      this.showChangePasswordTwo = val;
    },
    sendsms() {
      if (this.mobileNo) {
        this.timer = setInterval(() => {
          this.time--;
          if (this.time === 0) {
            clearInterval(this.timer);
            this.isverifi = true;
            this.time = 60;
          }
        }, 1000);
        if (this.isverifi) {
          this.isverifi = false;
          this.sendRequest();
        }
      } else {
        this.$message({
          showClose: false,
          message: "请选择手机号码",
          type: "error"
        });
      }
    },
    async sendRequest() {
      try {
        await payRollApi.sendCode(this.mobileNo);
      } catch (error) {
        clearInterval(this.timer);
        this.isverifi = true;
        this.time = 60;
      }
    }
  }
};
</script>
<style lang="scss">
.change-pwd-dialog .el-dialog /deep/ .el-dialog__body {
  text-align: left;
}
</style>
<style lang="scss" scoped>
.dialog_content {
  overflow: hidden;
  .progress {
    text-align: center;
    margin: 30px 0 30px 0;
    > div {
      display: inline-block;
    }
    .progres_number {
      i {
        display: inline-block;
        width: 25px;
        line-height: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        font-style: normal;
        color: #fff;
        margin-right: 10px;
      }
      span {
      }
    }
    .progress_light {
      i {
        background-color: #3e90fe;
      }
      span {
        color: #3e90fe;
      }
    }
    .progress_dark {
      i {
        background-color: #a2c9ef;
      }
      span {
        color: #d5dae3;
      }
    }
    .progress-icon {
      margin: 0 10px 0 10px;
      color: #3e90fe;
      font-size: 24px;
    }
  }
  .mobile-input {
    width: 350px;
    margin-left: 103px;
  }
  .input_pwd {
    width: 455px;
    margin: 20px auto;
    .el-input {
      display: inline-block;
      width: 350px;
      margin-right: 12px;
    }
    en-button {
      display: inline-block;
    }
  }
}
.header-title {
  text-align: left;
  margin-top: 5px;
  .prompt-title {
    font-size: 16px;
  }
  .prompt-info {
    color: #a9b5c6;
  }
}
</style>
