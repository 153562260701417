<template>
  <div class="send-page-wrap">
    <div class="importPayroll">
      <div class="top-nav">
        <div class="top-nav-left">
            <en-icon name="houtui" size="small" @click.native='$router.go(-1)'> </en-icon>
            <span>导入工资条</span>
        </div>
        <div class="top-nav-step">
          <en-step-list :step-options="stepOptions" :current-step="stepIndex" />
        </div>
        <div class="top-nav-right" @click="send()">
          <en-button>立即发送</en-button>
        </div>
        <div class="top-nav-right2" @click="sendDelay">
          <en-button type="success">
            延迟发送
          </en-button>
        </div>
        <div class="top-nav-right3">
          <en-button type="normal" @click="prevStep">
            上一步
          </en-button>
        </div>
      </div>
    </div>
    <div class="content" >
      <div class="content-inner">
        <div class="content-left">
          <el-input v-model="filterkeys" placeholder="请输入关键字"></el-input>
          <div class="content-left-inner" v-if="checkData">
            <div v-for="(value, index) in filterCheckDatas" :key="index" class="item" :class="activeClass == index ? 'active' : ''" @click="getItem(value, index)">
              {{ value[value.refInfo.nameKey] }}
            </div>
          </div>
        </div>
        <div class="content-right" v-en-loading='loading'>
          <table v-if="selectData">
            <tr class="tr-title" v-if="selectPayForm&&selectPayType">
              <td>{{selectPayForm.dateStr}}{{selectPayType.label}}</td>
            </tr>
            <tr>
              <td>姓 名</td>
              <td>{{ selectData.refInfo.name }}</td>
            </tr>
            <tr >
              <td>实发金额</td>
              <td>{{ selectData.refInfo.payment }}</td>
            </tr>
            <tr v-for="colItem in labelList" :key="colItem.label">
              <td>{{colItem.label}}</td>
              <td>{{colItem.value}}</td>
            </tr>
          </table>
        </div>
        <div class="content-bottom">
          <el-checkbox v-model="checked">
            手动签收
          </el-checkbox>
        </div>
      </div>
    </div>
    <el-dialog title="延时发送" :visible.sync="dialogVisible" width="30%">
      <span>
        <el-date-picker
          v-model="selectDate"
          type="datetime"
          style="width:100%;"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="选择日期时间">
        </el-date-picker>
      </span>
      <span slot="footer" class="dialog-footer">
        <en-button class="sendtime" type="primary" @click="send(selectDate)">确 定</en-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import { request } from "en-js";
import { payRollApi } from "@/api/payRoll";

export default {
  name: "sendNote",
  components: {},
  data() {
    return {
      stepOptions: ["上传工资表", "校验数据", "发送通知"],
      stepIndex: 3,
      loading: null,
      filterkeys: "",
      checked: false,
      dialogVisible: false,
      activeClass: 0,
      sendType: 0,
      selectDate: "",
      selectData: {}
    };
  },
  created() {
    if (!this.checkData) {
      this.$router.replace("/payroll/checkData");
    } else {
      this.selectData = this.checkData.datas[0];
    }
  },
  computed: {
    ...mapGetters("payRollModel", {
      selectPayType: "storedSelect",
      selectPayForm: "storedForm",
      checkData: "getterCheckData",
      refInfo: "getterRefInfo"
    }),
    noShowList() {
      return this.checkData.saveColList.filter((item) => Number(item.isShow) === 1).map((col) => col.fieldName);
    },
    filterCheckDatas() {
      return this.checkData.datas.filter((item) => item[item.refInfo.nameKey].indexOf(this.filterkeys) > -1);
    },
    labelList() {
      return Object.keys(this.selectData).filter((item) => !["refInfo"].includes(item) && !this.noShowList.includes(item)).map((ele) => {
        let value = "";
        let sort = 0;// 用于字段显示排序  部分字段放在前面显示  为0则依次往后排
        switch (ele) {
          case this.selectData.refInfo.nameKey:
            value = this.selectData.refInfo.name;
            sort = 3;
            break;
          case this.selectData.refInfo.enAccountKey:
            value = this.selectData.refInfo.enAccount;
            sort = 2;
            break;
          case this.selectData.refInfo.paymentKey:
            value = this.selectData.refInfo.payment;
            sort = 1;
            break;
          case "":
            value = this.selectData[ele];
            sort = 4;
            ele = "序号";
            break;
          default:
            value = this.selectData[ele];
            break;
        }
        const res = {
          label: ele,
          value,
          sort
        };
        return res;
      }).sort((a, b) => b.sort - a.sort);
    }
  },
  methods: {
    @request(true, "loading")
    async sendNotify(submitData) {
      // 接口请求
      console.log(submitData);
      await payRollApi.addPaySlips(submitData);
      this.$message.success(`${submitData.sendType === "1" ? "设置" : "发送"}成功`);
      this.nextStep();
    },
    send(sendTimeD) {
      const dateList = this.selectPayForm.date.split("-");
      const payYear = dateList[0];
      const payMonth = Number(dateList.pop());
      const submitData = {
        saveColList: JSON.stringify(this.checkData.saveColList),
        datas: JSON.stringify(this.checkData.datas),
        refInfo: JSON.stringify(this.refInfo),
        payYear,
        payMonth: `${payMonth}`,
        remark: this.selectPayForm.desc,
        isSign: `${Number(!this.checked)}`,
        payType: `${this.selectPayType.value}`
      };
      if (this.dialogVisible) {
        if (!sendTimeD) return;// 如果没选择时间则直接弹出
        submitData.sendType = "1";

        submitData.sendTime = sendTimeD;
        this.dialogVisible = false;
      } else {
        submitData.sendType = "0";
      }
      this.dialogVisible = false;
      this.sendNotify(submitData);
    },
    sendDelay() {
      this.dialogVisible = true;
    },
    prevStep() {
      this.$router.push({ path: "/payroll/checkData" });
    },
    nextStep() {
      this.$router.replace({ path: "/payroll" });
    },
    getItem(value, index) {
      this.activeClass = index;
      this.selectData = value;
    }
  }
};
</script>
<style lang="scss">
.send-page-wrap {
  .el-input__icon {
    line-height: 35px !important;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 600px;
  }
  .el-dialog /deep/ .el-dialog__header {
    text-align: left;
  }
  .el-date-editor.el-input,
  .send-page-wrap .el-date-editor.el-input__inner {
    width: 470px;
  }
}
</style>

<style lang="scss" scoped>
#app .full-page {
  background-color: #eee;
}
.importPayroll {
  float: left;
  width: 100%;
  .top-nav {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    .top-nav-left {
      float: left;
      margin: 20px 0 0 20px;
      .en-icon {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
        margin: 3px 0 0 3px;
        font-weight: 600;
        color: #333;
      }
    }
    .top-nav-step {
      display: flex;
      height: 100%;
      width: 80%;
      justify-content: center;
      align-items: center;
    }
    .top-nav-right {
      float: right;
      margin-top: -45px;
      margin-right: 15px;
    }
    .top-nav-right2 {
      float: right;
      margin-top: -45px;
      margin-right: 105px;
    }
    .top-nav-right3 {
      float: right;
      margin-top: -45px;
      margin-right: 195px;
    }
  }
}
.content {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #eee;
  padding-top: 70px;
  .content-inner {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: left;
    position: relative;
    .content-left {
      width: 315px;
      height: 96%;
      float: left;
      border: 1px solid #eee;
      border-radius: 5px;
      padding: 10px;
      position: relative;
      .content-left-inner {
        overflow: auto;
        position: absolute;
        left: 0;
        width: 100%;
        height: 93%;
        .item {
          cursor: pointer;
          width: 280px;
          height: 40px;
          margin: 0 auto;
          line-height: 40px;
          border: 1px solid #eee;
          border-radius: 5px;
          margin-top: 10px;
          font-size: 12px;
          color: #69717d;
          text-indent: 20px;
        }
      }
    }
    .content-right {
      margin-left: 325px;
      height: 96%;
      border: 1px solid #eee;
      border-radius: 5px;
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 300px;
      table {
        border: 1px solid #eee;
        border-collapse: collapse;
        width: 100%;
        font-size: 14px;
        .tr-title {
          td {
            border: 0 none;
            font-weight: 600;
            background-color: #fff;
          }
        }
        tr {
          td {
            border: 1px solid #eee;
            border-collapse: collapse;
            padding: 16px 0 16px 12px;
          }
          td:nth-child(1) {
            color: #333;
            width: 180px;
          }
          td:nth-child(2) {
            color: #666;
          }
        }
        tr:nth-child(odd) {
          background-color: #f7f9f9;
        }
        tr:last-child {
          background-color: #fff;
        }
      }
    }
    .content-bottom {
      width: 100%;
      float: left;
      height: 50px;
      margin-top: 10px;
      background-color: #fff;
    }
  }
  .sendtime {
    margin-top: 70px;
  }
}
.active {
  background-color: #3e90fe;
  color: #fff !important;
}
</style>
