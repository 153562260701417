var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "communicate",
      attrs: {
        title: "选择联系人",
        visible: _vm.isCommunicateShow,
        width: "900px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isCommunicateShow = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("en-transfer", {
            attrs: {
              type: _vm.headWayTransfer.type,
              list: _vm.headWayTransfer.list,
              title: _vm.headWayTransfer.title,
              "label-key": _vm.headWayTransfer.labelKey,
              "id-key": _vm.headWayTransfer.idKey,
              "show-head": _vm.headWayTransfer.withHead,
              "children-key": _vm.headWayTransfer.childrenKey,
              "img-path": _vm.headWayTransfer.imgPath,
            },
            model: {
              value: _vm.headWayTransfer.resultList,
              callback: function ($$v) {
                _vm.$set(_vm.headWayTransfer, "resultList", $$v)
              },
              expression: "headWayTransfer.resultList",
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "en-button",
            { attrs: { type: "primary" }, on: { click: _vm.closeLink } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }