var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("button", { on: { click: _vm.prompt } }, [_vm._v(" 提示弹窗 ")]),
      _c("prompt", {
        attrs: { "show-prompt": _vm.showPrompt },
        on: { closePrompt: _vm.getClosePrompt },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }