<template>
  <div>
    <div class="PaystubRight">
      <div class="top-nav">
        <div class="top-nav-left">
          <router-link to="/payroll">
            <en-icon
              name="houtui"
              size="small"
            >
            </en-icon>
            <span>王培培6月的工资条</span>
          </router-link>
        </div>
        <div class="top-nav-right">
          <en-image-setting
            name="goutong-kuaijiecaozuo"
            fill="#3e90fe"
            @click.native="linkup"
          >
            沟通
          </en-image-setting>
        </div>
      </div>
      <div class="info-table-wrap">
        <div class="info-table">
          <table>
            <tr>
              <td>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名</td>
              <td>001</td>
            </tr>
            <tr>
              <td>工&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号</td>
              <td>001</td>
            </tr>
            <tr>
              <td>部&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门</td>
              <td>研发部</td>
            </tr>
            <tr>
              <td>手&nbsp;&nbsp;机&nbsp;&nbsp;号</td>
              <td>15022305769</td>
            </tr>
            <tr>
              <td>河洛 账号</td>
              <td>15022305769</td>
            </tr>
            <tr>
              <td>基本工资</td>
              <td>5,000.00</td>
            </tr>
            <tr>
              <td>缴纳个税</td>
              <td>500.00</td>
            </tr>
            <tr>
              <td>补贴金额</td>
              <td>200.00</td>
            </tr>
            <tr>
              <td>实发金额</td>
              <td>5,000.00</td>
            </tr>
            <tr>
              <td>手&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;签</td>
              <td>
                <img
                  src="@/assets/qianming.jpg"
                  alt=""
                >
              </td>
              <!-- <span>重签</span> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!--沟通弹窗-->
    <communicate
      :is-communicate-show="isCommunicateShow"
      @closeLinkup="getCloseLinkup"
    ></communicate>
  </div>
</template>

<script>
import Communicate from "@/views/payRoll/common/communicate";

export default {
  name: "PaystubHrSign",
  components: { Communicate },
  data() {
    return { isCommunicateShow: false };
  },
  computed: {},
  methods: {
    linkup() {
      this.isCommunicateShow = true;
    },
    getCloseLinkup(val) {
      this.isCommunicateShow = val;
    }
  }
};
</script>
<style lang="scss">
.top-nav-right {
  .text {
    font-size: 14px !important;
  }
}
</style>
<style lang="scss" scoped>
#app .full-page {
  background-color: #1d2532;
  padding: 10px;
  overflow: hidden;
}
.PaystubRight {
  background: #fff;
  height: 100%;
  border-radius: 5px;
  text-align: left;
  position: relative;
  .top-nav {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #eee;
    .top-nav-left {
      float: left;
      margin: 15px 0 0 20px;
      .en-icon {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
        margin: 3px 0 0 3px;
        font-weight: 600;
        color: #333;
      }
    }
    .top-nav-right {
      float: right;
      margin: 15px 15px 0 0;
    }
  }
  .info-table-wrap {
    width: 100%;
    height: 100%;

    .info-table {
      background: #fff;
      height: 100%;
      position: relative;
      overflow: auto;
      padding-bottom: 50px;
      table {
        border: 1px solid #eee;
        border-collapse: collapse;
        width: 100%;
        font-size: 14px;
        tr {
          td {
            border: 1px solid #eee;
            border-collapse: collapse;
            padding: 16px 0 16px 12px;
          }
          td:nth-child(1) {
            color: #333;
            width: 230px;
          }
          td:nth-child(2) {
            color: #666;
          }
        }
        tr:nth-child(odd) {
          background-color: #f7f9f9;
        }
        tr:last-child {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
