<template>
  <div class='payroll-empty-wrap'>
    <div class="top-nav">
      <div class="back">
          <en-icon
            name="houtui"
            size="20"
            @click.native='$router.go(-1)'
          ></en-icon> <span>工资条管理</span>
      </div>
    </div>
    <div class="empty-wrap">
      <div class="empty">
        <img
          src="@/assets/notimport.png"
          alt=""
        >
        <p>您还未导入工资条哦~</p>
        <p>请点击 “<router-link tag="a" to="/payroll/importPayroll">添加</router-link>” 进行导入</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaystubEmpty",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.payroll-empty-wrap{
  background-color: #fff;
}
.top-nav {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #eee;
  text-align: left;
  overflow: hidden;
  .back {
    cursor: pointer;
    font-weight: 600;
    margin: 15px 0 0 20px;
    .en-icon {
      vertical-align: top;
    }
    span {
      display: inline-block;
      margin-top: 3px;
      font-size: 16px;
      color: #333;
    }
  }
}
.empty-wrap {
  overflow: auto;
  width: 100%;
  height: 100%;
  .empty {
    margin: 110px auto;
    text-align: center;
    p {
      color: #9dabbf;
      font-size: 12px;
      a {
        color: #3e90fe;
        text-decoration: none;
      }
    }
  }
}
</style>
