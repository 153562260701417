<template>
  <div class="graph">
    <div class="graph-inner">
      <div class="title">
        <div class="back">
          <router-link to="/payroll">
             <en-icon
              class='back-icon'
               color='#A9B5C6'
              name="fanhuishangcengji"
              size="small"
            ></en-icon>
            <span>统计图</span>
          </router-link>
        </div>
      </div>
      <div class="graph-wrap">
        <div class="graph-search">
          <el-form
            ref="form"
            label-width="20px"
          >
            <span>统计范围</span>
            <el-form-item>
              <en-select
                v-model="curRange"
                placeholder="请选择统计范围"
                value-key='id'
                data-mode='data'
                :data="rangeList"
                :props="{label:'name',value:'id'}"
                @change="rangeChange"
              >
              </en-select>
            </el-form-item>
            <el-form-item>
              <en-select
                v-model="graphType"
                :data="typeList"
                multiple
                :props="{label:'text'}"
                @change="getEchartData"
              >
              </en-select>
            </el-form-item>
            <el-form-item>
              <en-button  @click="getEchartData">
                查询
              </en-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="graph_img">
          <div
            id="chart-part1"
            class='chat-img'
            ref="chart1"
            style="width:100%;"
          ></div>
        </div>
        <div class="graph_table">
          <en-select
            v-model="curSelectMonth"
            class="table-select"
            data-mode='data'
            value-key='templateId'
            :data="curRange.monthList"
            :props="{label:'mName',value:'templateId'}"
          >
          </en-select>
          <en-table :data="tableData"
                  v-en-loading="enLoading"
                  @filter-sort-change="handleFilterSortChange"  >
              <en-table-column type="index"  width="50"></en-table-column>
              <template v-for="(colItem,colIndex) in colList">
                <en-table-column
                  v-if="colItem.fieldName"
                  :filter-sort-config="{filterable:true, sortable:true}"
                  :filter-render="{name: 'ElInput', attrs: {placeholder: '请输入过滤词...'}}"
                  :title="colItem.fieldName"
                  :prop="colItem.fieldName"
                  :data="colItem"
                    :key="colIndex"></en-table-column>
              </template>
              <en-table-column  title="状态"    width="120"  >
                <template slot-scope="scope">
                  <div>
                   <el-tag size="small" :type="statusType(scope.row.status)">{{scope.row.status|statusFilter}}</el-tag>
                  </div>
                </template>
              </en-table-column>
            </en-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { request } from "en-js";
import { payRollApi } from "@/api/payRoll";

export default {
  name: "Graph",
  components: {},
  data() {
    return {
      filterData: [], // 过滤条件
      sortData: {}, // 排序条件
      curRange: { monthList: [] }, // 当前选择范围
      graphType: ["1"], // 图表数据类型  默认总金额
      rangeList: [], // 统计范围
      curSelectMonth: "", // 当前选择的月份
      colList: [], // 表头字段列表
      enLoading: null,
      typeList: [
        { value: "1", text: "总金额" },
        { value: "2", text: "薪资" },
        { value: "3", text: "奖金" },
        { value: "4", text: "补贴" },
        { value: "5", text: "报销" },
        { value: "6", text: "薪资补发" }

      ],
      loading: false,
      tableData: []
    };
  },
  computed: {},
  mounted() {
    this.getDataList();
  },
  filters: {
    statusFilter(s) {
      return ["未发送", "未读", "已读", "已撤回", "已签收"][s - 1];// 1未发送，2未读，3已读，4已撤回，5已签收
    }
  },
  methods: {
    statusType(s) {
       return ["warning", "", "info", "danger", "success"][s - 1];// 1未发送，2未读，3已读，4已撤回，5已签收
    },
    // 查询统计选择范围
    async getDataList() {
      const resp = await payRollApi.queryPaySlipsYearList("0");
      this.rangeList = resp.yearList.map((item) => ({
        name: `${item.payYear}年`,
        id: item.payYear,
        monthList: item.monthList
      }));
      this.curRange = this.rangeList[0];
      this.curSelectMonth = this.curRange.monthList[0];
      this.getEchartData();
    },
    // 统计范围选择回调
    rangeChange(v) {
      this.curSelectMonth = v.monthList[0];
      this.getEchartData();
    },
    handleFilterSortChange({
      column, data, filterData, sortData
    }) {
      console.log({
        column, data, filterData, sortData
      });
      this.sortData = sortData;
      this.filterData = filterData ? JSON.parse(filterData) : [];
      this.loadTableData();
    },
    @request(true, "enLoading", {
      subTitle: "正在加载中..."
    })
    async loadData() {
      if (this.curSelectMonth.templateId) {
        this.loading = true;
        const resp = await payRollApi.queryPaySlipsColList(this.curSelectMonth.templateId);
        // 工资条的表头字段集合
        this.colList = resp.colList;
        this.$nextTick(async() => {
          this.loadTableData();
          this.loading = false;
        });
      }
    },
    @request(true, "enLoading", {
      subTitle: "正在加载中..."
    })
    async loadTableData() {
      const dataResp = await payRollApi.queryPaySlipList(this.curSelectMonth.templateId, this.sortData, this.filterData);
      this.tableData = dataResp;
    },
    async getEchartData() {
      const { chart1 } = this.$refs;
      const payTypeList = this.graphType.toString();
      if (chart1) {
        const myChart = this.$charts.init(
          document.getElementById("chart-part1")
        );

        const res = await payRollApi.queryPaySlipTotal({
          payType: payTypeList,
          payYear: this.curRange.id
        });
        console.log(res);
        // const ydata = [];
        const xdata = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];
        let ymid = [{ "1月": 0 }, { "2月": 0 }, { "3月": 0 }, { "4月": 0 }, { "5月": 0 }, { "6月": 0 }, { "7月": 0 }, { "8月": 0 }, { "9月": 0 }, { "10月": 0 }, { "11月": 0 }, { "12月": 0 }];
        const series = [];
        const yName = ["总金额", "薪资", "奖金", "补贴", "报销", "薪资补发"];
        const lis = payTypeList !== "" ? payTypeList.split(",") : [];
        lis.forEach((item) => {
          res[item] = res[item] || [];
        });
        res && Object.keys(res).forEach((key, ind) => {
          const resItem = res[key];
          ymid = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          resItem.forEach((v) => {
            ymid[v.payMonth - 1] = v.payment;
          });
          const obj = {
            name: yName[ind],
            type: "line",
            label: {
              normal: {
                show: true,
                position: "top"
              }
            },
            data: ymid
          };
          series.push(obj);
        });
        myChart.clear();
        const option1 = {
          tooltip: {
            trigger: "axis"
          },
          color: ["#06cdc6", "#45b9ee", "#ff8463", "#4bd793",
            "#94bcd3", "#f79451", "#66dfe0", "#5de674",
            "#b6a2de", "#e1d8ad", "#60c0dd", "#c4ccd3",
            "#99cccc", "#a8f15f", "#fcce10", "#4ebca9",
            "#2ec7c9", "#cbd570", "#73b0ee", "#99a9cc"
          ],
          legend: {
            x: "center",
            icon: "roundRect",
            itemGap: 10,
            bottom: "30px",
            data: yName
          },
          grid: {
            bottom: "120px"
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            splitLine: {
              lineStyle: {
                color: ["#eee"]
              }
            },
            axisLine: {
              lineStyle: {
                color: ["#999"]
              }
            },
            data: xdata
          },
          yAxis: {
            splitLine: {
              lineStyle: {
                color: ["#eee"]
              }
            },
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value}"
            },
            axisLine: {
              lineStyle: {
                color: ["#999"]
              }
            },
            type: "value"
          },
          series
        };
        myChart.setOption(option1);
        window.addEventListener("resize", () => {
          myChart.resize();
        });
      }
    }
  },
  watch: {
    curSelectMonth: {
      handler(v) {
        console.log("newva", v);
        this.loadData();
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
.graph {
  background-color: #1d2532;
  padding: 10px;
  .back-icon{
     margin-right: 5px;
    vertical-align: middle;
  }
  .graph-inner {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom:20px;
    .title {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #eee;
      text-align: left;
      font-weight: 600;
      .back {
        float: left;
        margin: 15px 0 0 20px;
        span {
          font-size: 14px;
          display: inline-block;
          vertical-align: top;
          margin: 3px 0 0 3px;
          font-weight: 600;
          color: #333;
        }
      }
    }
    .graph-wrap {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    .graph-search {
      background-color: #ebf0f6;
      width: 100%;
      text-align: left;
      height: 45px;
      padding: 5px 0 0 20px;
      span {
        font-size: 12px;
      }
      .el-form-item {
        display: inline-block;
      }
    }
    .graph_img {
      .chat-img{
        height:50vh;
      }
    }
    .graph_table {
      border-top: 1px solid #eee;
      margin-top: 10px;
      padding-bottom: 20px;
      .table-select {
        margin: 10px 0 10px 10px;
      }
      .bottom-nav {
        width: 100%;
        height: 65px;
        position: fixed;
        bottom: 15px;
        right: 20px;
      }
    }
  }
}
</style>
