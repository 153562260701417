var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "PaystubRight" }, [
        _c("div", { staticClass: "top-nav" }, [
          _c(
            "div",
            { staticClass: "top-nav-left" },
            [
              _c(
                "router-link",
                { attrs: { to: "/payroll" } },
                [
                  _c("en-icon", { attrs: { name: "houtui", size: "small" } }),
                  _c("span", [_vm._v("王培培6月的工资条")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "top-nav-right" },
            [
              _c(
                "en-image-setting",
                {
                  attrs: { name: "goutong-kuaijiecaozuo", fill: "#3e90fe" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.linkup.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" 沟通 ")]
              ),
            ],
            1
          ),
        ]),
        _vm._m(0),
      ]),
      _c("communicate", {
        attrs: { "is-communicate-show": _vm.isCommunicateShow },
        on: { closeLinkup: _vm.getCloseLinkup },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info-table-wrap" }, [
      _c("div", { staticClass: "info-table" }, [
        _c("table", [
          _c("tr", [
            _c("td", [_vm._v("姓       名")]),
            _c("td", [_vm._v("001")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("工       号")]),
            _c("td", [_vm._v("001")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("部       门")]),
            _c("td", [_vm._v("研发部")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("手  机  号")]),
            _c("td", [_vm._v("15022305769")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("河洛 账号")]),
            _c("td", [_vm._v("15022305769")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("基本工资")]),
            _c("td", [_vm._v("5,000.00")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("缴纳个税")]),
            _c("td", [_vm._v("500.00")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("补贴金额")]),
            _c("td", [_vm._v("200.00")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("实发金额")]),
            _c("td", [_vm._v("5,000.00")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("手       签")]),
            _c("td", [
              _c("img", {
                attrs: { src: require("@/assets/qianming.jpg"), alt: "" },
              }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }