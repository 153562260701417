var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.enLoading,
          expression: "enLoading",
        },
      ],
      staticClass: "paystub-right",
    },
    [
      _vm.param.hasData
        ? _c("div", { staticClass: "flex-wrap" }, [
            _c("div", { staticClass: "top-nav" }, [
              _c("div", { staticClass: "top-nav-left" }, [
                _c("span", [_vm._v(_vm._s(this.payDate))]),
              ]),
              _vm.idType !== "payRollId"
                ? _c(
                    "div",
                    { staticClass: "top-nav-right" },
                    [
                      _c(
                        "en-image-setting",
                        {
                          attrs: { name: "goutong", fill: "#3e90fe" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.linkup.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" 沟通 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "user-nav" }, [
              _c(
                "div",
                { staticClass: "name-content" },
                [
                  _c("en-user-logo", {
                    attrs: { "user-name": _vm.userName, size: "40" },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.userName))]),
                ],
                1
              ),
              _c("div", { staticClass: "money-content" }, [
                _c("span", [_vm._v("实发金额：")]),
                _c("i", [_vm._v(_vm._s(this.payRollDetail.refInfo.payment))]),
              ]),
            ]),
            _c("div", { staticClass: "info-table" }, [
              _vm.colList.length > 0
                ? _c(
                    "table",
                    [
                      _vm._l(_vm.colList, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { attrs: { width: "208" } }, [
                            _vm._v(_vm._s(item.fieldName)),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.payRollDetail[item.fieldName])),
                          ]),
                        ])
                      }),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.param.hasSign,
                              expression: "param.hasSign",
                            },
                          ],
                        },
                        [
                          _c("td", { attrs: { width: "208" } }, [
                            _vm._v("手       签"),
                          ]),
                          !_vm.param.isSaved
                            ? _c("td", [
                                _c("canvas", {
                                  attrs: {
                                    id: "canvas",
                                    width: "300",
                                    height: "100",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "againSign",
                                    on: { click: _vm.toClear },
                                  },
                                  [_vm._v("重签")]
                                ),
                              ])
                            : _vm._e(),
                          _vm.param.isSaved
                            ? _c("td", [
                                _c("img", {
                                  attrs: {
                                    width: "300",
                                    src: _vm.param.signUrl,
                                    alt: "",
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.param.hasSign && !_vm.param.isSaved,
                    expression: "param.hasSign && !param.isSaved",
                  },
                ],
                staticClass: "bottom-nav",
              },
              [
                _c("en-button", { on: { click: _vm.toSave } }, [
                  _vm._v(" 签收 "),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.param.hasData && !_vm.enLoading
        ? _c(
            "div",
            { staticClass: "no-data" },
            [_c("en-result", { attrs: { type: "NoData" } })],
            1
          )
        : _vm._e(),
      _vm.showaddChats
        ? _c("add-chats", { ref: "addPerson", on: { change: _vm.sendMessage } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }