<template>
  <div class="paystub-left">
    <div class="paystub-left-top">
      <div @click="back" style="display: flex;align-items: center;">
        <en-icon name="houtui" size="small"> </en-icon>
        <span>我的工资条</span>
      </div>
    </div>
    <div class="paystub-left-inner year-sidecentbar">
      <en-file-select-board
              :data="treeData"
              node-key="id"
              highlight-current
              ref="filtmenu"
              accordion
              expand-icon="gongzi"
              collpase-icon="gongzi"
              :props="defaultProps"
              @node-click="nodeClick"
            >
            </en-file-select-board>
    </div>
  </div>
</template>

<script>
import { payRollApi } from "@/api/payRoll";

export default {
  name: "paystub-left",
  components: {},
  data() {
    return {
      treeData: [],
      defaultProps: {
        label: "name",
        value: "id",
        disabled: (item) => item.disabled
      },
      // 路由带过来的id
      curId: ""
    };
  },
  mounted() {
    const { id } = this.$route?.query || {};
    this.curId = id;
    this.getDataList();
  },
  computed: {},
  methods: {
    nodeClick(data) {
      if (data.disabled) {
        // this.$emit("onLeftItemClick", { id: "", name: data.name });
      } else {
        this.$emit("onLeftItemClick", data);
      }
    },
    singleClick(data, node, self) {
      console.log(data, node, self);
    },
    async getDataList() {
      const resp = await payRollApi.queryPaySlipsYearList("1");
      // 洗数据，支持控件展示
      resp.yearList.forEach((element) => {
        element.mName = String(element.payYear);
      });
      this.treeData = resp.yearList.map((item) => {
        const parentNode = {
          children: [],
          id: `${item.payYear}`,
          name: `${item.payYear}年`,
          disabled: true
        };
        if (item.monthList.length) {
          parentNode.children = item.monthList.map((monthItem) => ({ id: monthItem.templateId, name: monthItem.mName }));
        }
        return parentNode;
      });
      // 默认展示第一条数据
      if (this.treeData.length > 0) {
        // 有数据默认展示选中
        if (this.curId) {
          // 查询详细数据
          const curData = await payRollApi.queryPaySlipsDetail(this.curId) || {};
          // 取出templateId
          if (curData.templateId) {
            let obj = {};
            this.treeData.forEach((element) => {
              (element.children || []).forEach((element2) => {
                if (element2.id === curData.templateId) {
                  obj = element2;
                }
              });
            });
            // 得到展示的模板 查询模板数据 并展示
            if (obj.id) {
              this.$emit("onLeftItemClick", obj);
            } else if (this.treeData[0].children.length > 0) {
              this.$emit("onLeftItemClick", this.treeData[0].children[0]);
            }
          } else if (this.treeData[0].children.length > 0) {
            this.$emit("onLeftItemClick", this.treeData[0].children[0]);
          }
        } else if (this.treeData[0].children.length > 0) {
          this.$emit("onLeftItemClick", this.treeData[0].children[0]);
        }
        this.$nextTick(() => {
          this.$emit("leftLoadEnd", true);
        });
      } else {
        this.$emit("leftLoadEnd", false);
      }
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style>
.paystub-left .el-tree-node__content {
  padding-left: 10px !important;
  text-align: left;
}
</style>

<style lang="scss" scoped>
.paystub-left {
  width: 284px;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  float: left;
  overflow: auto;
  .paystub-left-inner {
    padding: 16px;
  }
  .paystub-left-top {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #eee;
    line-height: 50px;
    padding-left: 15px;
    .en-icon {
      width: 22px;
      height: 22px;
    }
    span {
      font-size: 14px;
      display: inline-block;
      vertical-align: top;
      margin: 3px 0 0 3px;
      font-weight: bold;
      color: #333;
    }
  }
  .year-sidecentbar {
    padding: 10px 20px 40px;
    overflow-y: auto;
    max-height: 90%;
    /deep/ .en-file-select-board-container .tree-node-level-1 {
        background: white;
        border: 1px solid #e8ecf2;
        border-radius: 4px;
        margin-bottom: 10px;

    }
   /deep/ .el-tree-node__content .el-tree-node__expand-icon{
     margin-left: 0;
   }
    /deep/ .en-file-select-board-container  .tree-node-level-1.is-expanded > .el-tree-node__content {
        background-color: #3e90fe !important;
        color: white !important;
        padding-left: 10px !important;
        border-radius: 4px;
        .el-tree-node__expand-icon>.en-icon{
          color: #fff!important;
        }
        &:hover{
          .el-dropdown-link{
            color: #fff;
          }
        }
    }

    /deep/  .custom-tree-node{
            display: flex;
            align-items: center;
            justify-content:space-between;
            width: 100%;
            .label{
              flex: 1;
            }
            .btns{
              width: 30px;
              flex: 0;
              margin-top: 6px;
              color: inherit;
              visibility: hidden;
            }
          }
    /deep/ .el-tree-node__content:hover{
      .custom-tree-node .btns{
        visibility: visible;
      }
    }
  }
}
</style>
