<template>
  <div class="content">
    <div class="content-inner">
      <div class="content-block">
        <div class="content-logo">
          <img
            src="@/assets/favicon.png"
            alt=""
          >
          <span><i>河洛</i><br>智能财务</span>
        </div>
        <p class="check">
          查看工资条
        </p>
        <p class="info">
          您好：<br>湖南英富莱网络技术有限公司向您发送了2019年11月薪资明细，请查阅。如有疑问请随时沟通<br>祝好!
        </p>
        <en-button @click="$router.push({path:'/payroll/paystubNav'})">查看工资条</en-button>
        <p class="info info_tit">
          本邮件由湖南英富莱网络技术有限公司HR使用河洛创建发送，河洛不为邮件内容负责。<br>直接回复本邮件将无法送达HR邮箱。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Check",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.content {
  background-color: #ecf1f7;
  overflow: auto;
  &-inner {
    background-color: #fff;
    width: 100%;
    height: 650px;
    margin-top: 130px;
    .content-block {
      width: 530px;
      margin: 0 auto;
      overflow: hidden;
      text-align: left;
      .content-logo {
        margin: 50px 0 30px 0;
        img {
          width: 38px;
          height: 28px;
          margin-right: 5px;
        }
        span {
          display: inline-block;
          width: 60px;
          color: #1fade6;
          font-size: 14px;
          text-align: left;
          i {
            font-style: normal;
            font-size: 20px;
          }
        }
      }
      .check {
        color: #3e90fe;
        font-size: 24px;
      }
      .info {
        color: #999;
        font-size: 12px;
        line-height: 30px;
      }
      .el-button--primary {
        width: 450px;
        height: 45px;
        font-size: 16px;
        margin: 70px 0 30px 0;
      }
      .info_tit {
        color: #b0bbcb;
      }
    }
  }
}
</style>
