var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "content-inner" }, [
      _c(
        "div",
        { staticClass: "content-block" },
        [
          _vm._m(0),
          _c("p", { staticClass: "check" }, [_vm._v(" 查看工资条 ")]),
          _vm._m(1),
          _c(
            "en-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.push({ path: "/payroll/paystubNav" })
                },
              },
            },
            [_vm._v("查看工资条")]
          ),
          _vm._m(2),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-logo" }, [
      _c("img", { attrs: { src: require("@/assets/favicon.png"), alt: "" } }),
      _c("span", [_c("i", [_vm._v("河洛")]), _c("br"), _vm._v("智能财务")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "info" }, [
      _vm._v(" 您好："),
      _c("br"),
      _vm._v(
        "湖南英富莱网络技术有限公司向您发送了2019年11月薪资明细，请查阅。如有疑问请随时沟通"
      ),
      _c("br"),
      _vm._v("祝好! "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "info info_tit" }, [
      _vm._v(
        " 本邮件由湖南英富莱网络技术有限公司HR使用河洛创建发送，河洛不为邮件内容负责。"
      ),
      _c("br"),
      _vm._v("直接回复本邮件将无法送达HR邮箱。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }