var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "root-layout" },
    [
      _c("topNav"),
      _c(
        "div",
        { staticClass: "paystub-right-right" },
        [
          _vm.id && _vm.showDetail
            ? _c("paystubRight", { attrs: { id: _vm.id, idType: "payRollId" } })
            : _vm._e(),
        ],
        1
      ),
      _c("prompt-index", { ref: "promptIndex", attrs: { mode: _vm.mode } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }