/*
 * @Author: Zhouql
 * @Date: 2020-07-02 10:14:23
 * @LastEditTime: 2020-11-20 17:35:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\mixins\myFolder\folderCommon.js
 */
import { foldersService } from "@/api/folders";
import { getFileTypeList } from "@/tools/folderVarCommon.js";

export default {
  methods: {
    // 查询手动同步树
    async getSyncRule() {
      async function recursionFetch(id) {
        const arr = await foldersService.getSyncRule({
          id
        });
        const result = arr.slice();
        const promises = [];
        result.forEach((item) => {
          if (item.hasChildren === "000") {
            promises.push((async() => {
              item.nodes = await recursionFetch(item.id);
            })());
          }
        });
        await Promise.all(promises);
        return result;
      }
      try {
        this.loading = true;
        const syncList = await recursionFetch();
        this.syncList = syncList;
        // console.log("shide xiala", this.syncList);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 查询模板分类
    async getTemplateClassify() {
      try {
        this.loading = true;
        const res = await foldersService.getTemplateClassify({

        });
        this.templateClass = res;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description: 获取文件后缀名
     * @param {type}
     * @return:
     */
    getSuffix(filename) {
      if (!filename || typeof filename !== "string") {
        return false;
      }
      const a = filename.split("").reverse().join("");
      const b = a.substring(0, a.search(/\./)).split("").reverse().join("");
      return b;
    },
    /**
     * @description: 根据后缀给相应icon和颜色
     * @param {arr, suffix}
     * @return:
     */
    findIconType(arr, suffix) {
      let iconName;
      let iconColor;
      arr.forEach((el) => {
        if (el.text === suffix) {
          iconName = el.iconName;
          iconColor = el.iconColor;
        }
      });
      return { iconName, iconColor };
    },
    /**
     * @description: 根据后缀给相应icon
     * @param {name}
     * @return:
     */
    suffix(name) {
      const suffixName = this.getSuffix(name);
      const fileList = getFileTypeList();
      const filetype = this.findIconType(fileList, suffixName);
      return filetype;
    }

  }
};
