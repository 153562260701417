<template>
  <div v-en-loading="isLoading">
    <paystubLeft @onLeftItemClick="onLeftItemClick" @leftLoadEnd="onLeftLoadEnd" v-show="param.hasData && !isLoading"></paystubLeft>
    <paystubRight v-if="selectItem.id" :leftData='selectItem' :id="selectItem.id" ref="payrollRight"></paystubRight>
    <div class="no-data"><en-result type="NoData" v-if="!param.hasData && !isLoading"></en-result></div>
  </div>
</template>

<script>
import PaystubLeft from "@/views/payRoll/user/paystub/paystubLeft";
import PaystubRight from "@/views/payRoll/user/paystub/paystubRight";

export default {
  components: {
    PaystubLeft,
    PaystubRight
  },
  data() {
    return {
      selectItem: {},
      templateId: "",
      isLoading: false,
      param: {
        hasData: true
      }
    };
  },
  mounted() {
    this.openFullScreen();
  },
  computed: {},
  methods: {
    /**
     * 左侧列表子级选项点击触发
     */
    onLeftItemClick(item) {
      this.selectItem = item;
    },
    onLeftLoadEnd(hasData) {
      this.isLoading = false;
      this.$set(this.param, "hasData", hasData);
    },
    openFullScreen() {
      this.isLoading = true;
    }
  }
};
</script>
<style lang="scss" scoped>
#app .full-page {
  background-color: #1d2532;
  padding: 10px;
  overflow: hidden;
}
.no-data{
  background: white;
  padding-top: 80px;
  height: 100%;
}
</style>
