<template>
  <div class="import-page-wrap">
    <div class="importPayroll">
      <div class="top-nav">
        <div class="top-nav-left">
          <en-icon name="houtui" size="small" @click.native='$router.go(-1)'> </en-icon>
          <span>导入工资条</span>
        </div>
        <div class="top-nav-step">
          <en-step-list :step-options="stepOptions" :current-step="stepIndex" />
        </div>
        <div class="top-nav-right">
          <en-button @click="nextStep" :disabled="uploadStatus !=='finish'">
            下一步
          </en-button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-inner">
        <el-form ref="payform" :model="form" :inline="true" >
          <el-form-item label-width="80px" label="薪资类型" class="divide-item">
            <en-select v-model="select"  placeholder="请选择" data-mode='data' class='full-item' value-key='value' :data="currentType"> </en-select>
          </el-form-item>
          <el-form-item label-width="80px" label="薪资日期" class="divide-item" prop="date" :rules="[
            {required: true, message: '请选择日期', trigger: 'change' }
          ]">
            <en-date-picker
                class='full-item'
                format="yyyy 年 MM 月"
                value-format="yyyy-MM"
                v-model="form.date"
                 :picker-options="pickerOptions"
                type="month"
                @change="dateChange"
                placeholder="选择年月">
            </en-date-picker>
          </el-form-item>
          <el-form-item label="薪资备注" label-width="80px" class='payroll-remark'>
            <el-input v-model="form.desc" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div class="upload_content">
          <img src="@/assets/gozi-shangchuan.png" alt="" class="img" v-if="!uploadFile" />
          <el-upload
            v-if="!uploadFile"
            ref="upload"
            :action="uploadUrl+'/importPaySlips'"
            :data='uploadParams'
            class="upload_lay"
            name="importFile"
            accept=".xlsx,.xls"
            :show-file-list='false'
            :headers="header"
            :on-success="handleSuccess"
            :on-error='handleError'
            :on-progress='handleProgress'
          >
            <en-button  type="success" size="small" class="upload_btn">
              立即上传工资表
            </en-button>
          </el-upload>
          <div class='upload-process' v-if="uploadFile">
            <en-upload-progress
               :iconColor='suffix(uploadFile.name).iconColor'
               :iconName='suffix(uploadFile.name).iconName'
               :fileName='uploadFile.name'
               :progress-status="uploadStatus"  :percent='uploadFile.percentage'></en-upload-progress>
               <i class='el-icon-error delete-upload' @click="uploadFile=null"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapMutations, mapGetters } from "vuex";
import folderComm from "@/mixins/myFolder/folderComm.js";
// import { payRollApi } from "@/api/payRoll";
import { apiBaseUrl } from "../../../../../baseUrl";

export default {
  name: "ImportPayroll",
  components: {},
  mixins: [folderComm],
  data() {
    return {
      uploadFile: null, // 当前上传的文件
      uploadStatus: "", // 当前上传状态
      stepOptions: ["上传工资表", "校验数据", "发送通知"],
      stepIndex: 1,
      form: {
        date: "",
        desc: "工资条"
      },
      fileList: [],
      currentType: [
        { value: 2, label: "薪资" },
        { value: 3, label: "奖金" },
        { value: 4, label: "补贴" },
        { value: 5, label: "报销" },
        { value: 6, label: "薪资补发" }
      ],
      pickerOptions: {
        disabledDate(time) { // 薪资日期年份可选内容为当前年度，当前年度往后1年，当前年度往前4年
          return time.getTime() > Date.now() + (365 * 24 * 60 * 60 * 1000) || time.getTime() < Date.now() - (4 * 365 * 24 * 60 * 60 * 1000);
        }
      },
      select: { value: 2, label: "薪资" },
      fileData: {}
    };
  },
  computed: {
    ...mapGetters(["urlParam", "appSessionId"]),
    uploadUrl() {
      return window.api;
    },
    header() {
      return {
        Authorization: sessionStorage.getItem("jwtToken")
      };
    }
  },
  created() {
    this.uploadParams = {
      appKey: this.urlParam.appKey,
      token: this.urlParam.token,
      ts: this.urlParam.ts,
      tenantId: "",
      appSessionId: this.appSessionId,
      channelType: "PC",
      channelcode: "PC"
    };
  },
  methods: {
    ...mapMutations("payRollModel", ["saveFileData", "saveSelect", "saveForm"]),

    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleSuccess(resp) {
      console.log(resp);
      if (resp.rspCode === "000000") {
        this.uploadStatus = "finish";
        this.fileData = resp.rspData;
        this.fileData.rspData.val = this.fileData?.rspData?.val?.filter((item) => Object.keys(item).some((key) => item[key] !== ""));
      } else {
        this.uploadFile = null;
        this.$message.error(resp.rspMsg);
      }
    },
    // 日期选择回调
    dateChange(v) {
      v = v || "";
      const dateArr = v.split("-");
      this.form.dateStr = dateArr.length > 1 ? `${dateArr[0]}年${dateArr[1]}月` : "";
    },
    handleProgress(event, file, fileList) {
      console.log(event, file, fileList);
      this.uploadFile = file;
      this.uploadStatus = "loading";
    },
    handleError() {
      this.uploadStatus = "error";
    },
    nextStep() {
      if (!this.uploadFile) {
        this.$message.error("请上传工资表");
        return;
      }
      this.$refs.payform.validate((valid) => {
        if (valid) {
          this.saveFileData(this.fileData.rspData);
          this.saveSelect(this.select);
          this.saveForm(this.form);
          this.$router.push({ path: "/payroll/checkData", query: { t: Date.now() } });
          return undefined;
        }
        console.log("error submit!!");
        return false;
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    // ...
    console.log(from.name);
    if (from.name === "checkData") {
      to.meta.keepAlive = true;
    } else {
      to.meta.keepAlive = false;
    }
    next();
  }
};
</script>
<style lang="scss">
.import-page-wrap {
  .el-input__icon {
    line-height: 35px !important;
  }
  // .el-date-editor.el-input,
  // .el-date-editor.el-input__inner {
  //   width: 600px;
  // }
}
</style>

<style lang="scss" scoped>
#app .full-page {
  background-color: #eee;
  overflow: hidden;
}
.importPayroll {
  float: left;
  width: 100%;
  .top-nav {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    .top-nav-left {
      width: 25%;
      float: left;
      margin: 20px 0 0 14px;
      font-size: 14px;
      .en-icon {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 14px;
        display: inline-block;
        vertical-align: top;
        margin: 3px 0 0 3px;
        font-weight: 600;
        color: #333;
      }
    }
    .top-nav-step {
      display: flex;
      height: 100%;
      width: 50%;
      justify-content: center;
      align-items: center;
    }
    .top-nav-right {
      float: right;
      margin-top: -45px;
      margin-right: 15px;
    }
  }
}
.content {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #eee;
  padding-top: 70px;
  .content-inner {
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .el-form {
      text-align: left;
      .el-form-item {
        text-align: left;

      }
      .divide-item{
        width: 47%;
        & /deep/ .el-form-item__content{
          width: calc(95% - 80px);

        }
        .full-item{
          width: 100%;
        }
      }
    }
    .payroll-remark{
      display: block;
      & /deep/ .el-form-item__content{
        width: calc(100% - 80px)!important;
      }
    }
    .upload_content {
      height: 100%;
      width: 100%;
      background-color: #f5f7fa;
      border: 1px solid #ddd;
      border-radius: 5px;
      text-align: center;
      flex: 1;
      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 100px;
      }
      .upload_lay {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        .upload_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          margin-top: 20px;
        }
      }
    }
  }
  .upload-process{
    margin: 20vh auto;
    position: relative;
    display: inline-block;
    .delete-upload{
      position: absolute;
      font-size: 16px;
      right: -8px;
      top: -8px;
      color: red;
      cursor: pointer;
    }
  }
}
</style>
