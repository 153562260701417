var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "paystub-left" }, [
    _c("div", { staticClass: "paystub-left-top" }, [
      _c(
        "div",
        {
          staticStyle: { display: "flex", "align-items": "center" },
          on: { click: _vm.back },
        },
        [
          _c("en-icon", { attrs: { name: "houtui", size: "small" } }),
          _c("span", [_vm._v("我的工资条")]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "paystub-left-inner year-sidecentbar" },
      [
        _c("en-file-select-board", {
          ref: "filtmenu",
          attrs: {
            data: _vm.treeData,
            "node-key": "id",
            "highlight-current": "",
            accordion: "",
            "expand-icon": "gongzi",
            "collpase-icon": "gongzi",
            props: _vm.defaultProps,
          },
          on: { "node-click": _vm.nodeClick },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }