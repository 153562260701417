var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkdata-page-wrap" }, [
    _c("div", { staticClass: "importPayroll" }, [
      _c("div", { staticClass: "top-nav" }, [
        _c(
          "div",
          { staticClass: "top-nav-left" },
          [
            _c("en-icon", {
              attrs: { name: "houtui", size: "small" },
              nativeOn: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            }),
            _c("span", [_vm._v("导入工资条")]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "top-nav-step" },
          [
            _c("en-step-list", {
              attrs: {
                "step-options": _vm.stepOptions,
                "current-step": _vm.stepIndex,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "top-nav-right" },
          [
            _c("en-button", { on: { click: _vm.checkData } }, [
              _vm._v(" 下一步 "),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "top-nav-right2" },
          [
            _c(
              "en-button",
              { attrs: { type: "normal" }, on: { click: _vm.prevStep } },
              [_vm._v(" 上一步 ")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "content-inner" }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _vm._v(
              " " +
                _vm._s(_vm.preForm && _vm.preForm.dateStr) +
                _vm._s(_vm.preSelect && _vm.preSelect.label) +
                "-"
            ),
            _c("span", { staticClass: "desc" }, [
              _vm._v(_vm._s(_vm.preForm && _vm.preForm.desc)),
            ]),
            _vm.preForm && _vm.preForm.desc
              ? _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.preForm.desc,
                      placement: "top-start",
                    },
                  },
                  [
                    _c("en-icon", {
                      attrs: { name: "tishi", color: "#F7BF27", size: "16" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-info" },
          [
            _vm.tableCol.length
              ? _c(
                  "en-table",
                  { attrs: { data: _vm.tableVal } },
                  _vm._l(_vm.tableCol, function (item, index) {
                    return _c("en-table-column", {
                      key: item.field,
                      attrs: {
                        prop: item.field,
                        label: item.name || "序号",
                        data: item,
                        "filter-sort-config": {},
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function ({ column }) {
                              return [
                                _c(
                                  "el-checkbox",
                                  {
                                    on: {
                                      change: (v) => {
                                        _vm.colCheckedChange(v, item, index)
                                      },
                                    },
                                    model: {
                                      value: _vm.checked[index],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.checked, index, $$v)
                                      },
                                      expression: "checked[index]",
                                    },
                                  },
                                  [_vm._v(_vm._s(column.title))]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "field" },
          [
            _c("div", { staticClass: "title" }, [_vm._v(" 识别字段 ")]),
            _c(
              "el-form",
              {
                ref: "pipeiform",
                attrs: {
                  model: _vm.form,
                  "label-width": "80px",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "45%", "margin-right": "30px" },
                        attrs: { label: "员工姓名", prop: "name" },
                      },
                      [
                        _c("en-select", {
                          attrs: {
                            placeholder: "请选择",
                            data: _vm.colList,
                            props: { label: "name", value: "field" },
                          },
                          on: { change: _vm.setChecked },
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "45%", "margin-right": "30px" },
                        attrs: { label: "实发金额", prop: "payment" },
                      },
                      [
                        _c("en-select", {
                          attrs: {
                            placeholder: "请选择",
                            data: _vm.colList,
                            props: { label: "name", value: "field" },
                          },
                          on: { change: _vm.setChecked },
                          model: {
                            value: _vm.form.payment,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "payment", $$v)
                            },
                            expression: "form.payment",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      {
                        key: _vm.isneedEmail + "enAccount",
                        staticStyle: { width: "45%", "margin-right": "30px" },
                        attrs: {
                          label: "河洛 账号",
                          prop: !_vm.isneedEmail ? "enAccount" : "",
                        },
                      },
                      [
                        _c("en-select", {
                          attrs: {
                            placeholder: "请选择",
                            data: _vm.colList,
                            props: { label: "name", value: "field" },
                          },
                          on: { change: _vm.setChecked },
                          model: {
                            value: _vm.form.enAccount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "enAccount", $$v)
                            },
                            expression: "form.enAccount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        key: _vm.isneedEmail + "mobile",
                        staticStyle: { width: "45%", "margin-right": "30px" },
                        attrs: {
                          label: "手 机 号",
                          prop: !_vm.isneedEmail ? "mobile" : "",
                        },
                      },
                      [
                        _c("en-select", {
                          attrs: {
                            placeholder: "请选择",
                            data: _vm.colList,
                            props: { label: "name", value: "field" },
                          },
                          on: { change: _vm.setChecked },
                          model: {
                            value: _vm.form.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mobile", $$v)
                            },
                            expression: "form.mobile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isneedEmail
                  ? _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "45%", "margin-right": "30px" },
                        attrs: { label: "邮箱地址", prop: "邮箱地址" },
                      },
                      [
                        _c("en-select", {
                          attrs: {
                            placeholder: "请选择",
                            data: _vm.colList,
                            props: { label: "name", value: "field" },
                          },
                          model: {
                            value: _vm.form.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "email", $$v)
                            },
                            expression: "form.email",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }