<template>
  <el-dialog
    class="communicate"
    title="选择联系人"
    :visible.sync="isCommunicateShow"
    width="900px"
    :before-close="handleClose"
  >
    <div class="content">
      <en-transfer
        v-model="headWayTransfer.resultList"
        :type="headWayTransfer.type"
        :list="headWayTransfer.list"
        :title="headWayTransfer.title"
        :label-key="headWayTransfer.labelKey"
        :id-key="headWayTransfer.idKey"
        :show-head="headWayTransfer.withHead"
        :children-key="headWayTransfer.childrenKey"
        :img-path="headWayTransfer.imgPath"
      >
      </en-transfer>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <en-button
        type="primary"
        @click="closeLink"
      >确 定</en-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "Communicate",
  components: {},
  props: ["isCommunicateShow"],
  data() {
    return {
      headWayTransfer: {
        labelKey: "label",
        idKey: "id",
        type: "tree",
        list: [],
        childrenKey: "children",
        filter: true,
        showHead: true,
        selfIcon: false,
        resultList: [],
        withHead: true,
        imgPath: "headWay"
      },
      // 关闭弹窗
      closeLinkup: false
    };
  },
  computed: {},
  mounted() {
    this.setHeadList();
    this.setTreeList();
  },
  methods: {
    handleClose() {
      this.$emit("closeLinkup", this.closeLinkup);
    },
    closeLink() {
      this.$emit("closeLinkup", this.closeLinkup);
    },
    setTreeList() {
      this.headWayTransfer.list = [
        {
          id: 1,
          label: "一级 1",
          children: [
            {
              id: 4,
              label: "二级 1-1",
              children: [
                {
                  id: 9,
                  label: "三级 1-1-1"
                }, {
                  id: 10,
                  label: "三级 1-1-2"
                }
              ]
            }
          ]
        },
        {
          id: 2,
          label: "一级 2",
          children: [
            {
              id: 5,
              disabled: true,
              label: "二级 2-1"
            }, {
              id: 6,
              label: "二级 2-2"
            }
          ]
        },
        {
          id: 3,
          label: "一级 3",
          children: [
            {
              id: 7,
              label: "二级 3-1"
            }, {
              id: 8,
              label: "二级 3-2"
            }
          ]
        }
      ];
    },
    setHeadList() {
      for (let i = 1; i < 10; i++) {
        this.headWayTransfer.list.push({
          id: i,
          name: `第 ${i} 带头像的数据`,
          disabled: Math.random() > 0.5,
          headWay: Math.random() > 0.5 ? "https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=1081685499,2381731667&fm=26&gp=0.jpg"
            : "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1415947974,2911123664&fm=26&gp=0.jpg"
        });
      }
    }
  }
};
</script>
<style>
.communicate .el-dialog /deep/ .el-dialog__header {
  text-align: left;
}
</style>
