<template>
  <div class="paystub-right" v-en-loading="enLoading">
    <div v-if="param.hasData" class='flex-wrap'>
      <div class="top-nav">
        <div class="top-nav-left">
          <span>{{ this.payDate }}</span>
        </div>
        <div class="top-nav-right" v-if="idType !== 'payRollId'">
          <en-image-setting name="goutong" fill="#3e90fe" @click.native="linkup">
            沟通
          </en-image-setting>
        </div>
      </div>
      <div class="user-nav">
        <div class="name-content">
          <en-user-logo :user-name="userName" size="40"></en-user-logo><span>{{ userName }}</span>
        </div>
        <div class="money-content">
          <span>实发金额：</span>
          <i>{{ this.payRollDetail.refInfo.payment }}</i>
        </div>
      </div>
      <div class="info-table">
        <table v-if="colList.length > 0">
          <tr v-for="(item, index) in colList" :key="index">
            <td width="208">{{ item.fieldName }}</td>
            <td>{{ payRollDetail[item.fieldName] }}</td>
          </tr>
          <tr v-show="param.hasSign">
            <td width="208">手&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;签</td>
            <td v-if="!param.isSaved">
              <canvas id="canvas" width="300" height="100"> </canvas>
              <span class="againSign" @click="toClear">重签</span>
            </td>
            <td v-if="param.isSaved">
              <img width="300" :src="param.signUrl" alt="" />
            </td>
          </tr>
        </table>
      </div>
      <div class="bottom-nav" v-show="param.hasSign && !param.isSaved">
        <en-button @click="toSave">
          签收
        </en-button>
      </div>
    </div>
    <div class="no-data" v-if="!param.hasData && !enLoading"><en-result type="NoData"></en-result></div>
   <!--沟通弹窗-->
    <add-chats ref="addPerson" v-if="showaddChats"  @change="sendMessage">
    </add-chats>
  </div>
</template>

<script>
import { request } from "en-js";
import { payRollApi } from "@/api/payRoll";
import addChats from "@/views/chat/view/addChats";
import Utils from "@/tools/chat";
import { team } from "@/api/chat";

export default {
  components: { addChats },
  props: {
    id: String,
    idType: String,
    leftData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      ctx: null,
      canvas: null,
      showaddChats: false,
      payRollDetail: {},
      colList: [],
      userName: "",
      payDate: "",
      param: {
        // 是否可以保存
        isSaved: false,
        // 是否有手签控件
        hasSign: false,
        // 手签的url
        signUrl: "",
        // 是否有详细数据
        hasData: false
      },
      enLoading: null
    };
  },
  computed: {},
  watch: {
    leftData: {
      handler() {
        this.param.isSaved = false;
        this.param.hasData = false;
        this.param.hasSign = false;
        this.param.signUrl = "";
        this.loadData();
      },
      immediate: true,
      deep: true
    },
    id: {
      handler() {
        this.param.isSaved = false;
        this.param.hasData = false;
        this.param.hasSign = false;
        this.param.signUrl = "";
        this.loadData();
      }
    }
  },
  mounted() {},
  methods: {
    initPage() {
      this.canvas = document.getElementById("canvas");
      if (this.canvas && this.canvas.getContext) {
        this.ctx = this.canvas.getContext("2d");
        const background = "#fff";
        this.ctx.lineCap = "round";
        this.ctx.fillStyle = background;
        this.ctx.lineWidth = 2;
        this.ctx.fillRect(0, 0, 350, 150);
        let flag = false;
        this.canvas.addEventListener("mousedown", () => {
          flag = true;
          this.ctx.beginPath();
        });
        this.canvas.addEventListener("mousemove", (e) => {
          if (flag) {
            this.ctx.lineTo(e.offsetX, e.offsetY);
            this.ctx.strokeStyle = "#333";
            this.ctx.stroke();
          }
        });
        this.canvas.addEventListener("mouseup", () => {
          this.ctx.closePath();
          flag = false;
        });
      }
    },
    // 发送消息
    async sendMessage(userlist) {
      if (userlist.length === 1) { // 列表存在单聊 直接选中
        const imUserId = userlist[0].imUserId;
        const session = this.$store.state.chat.sessionlist.find((item) => item.to === imUserId);
        if (session) {
          this.$store.dispatch("chat/setCurrSession", session.id);
          this.$router.push({
            path: "/chat",
            query: { id: session.id, unread: session.unread }
          });
          return;
        }
      }
      // 先同步资料
      const accounts = userlist.map((item) => item.imUserId) || [];
      this.$store.dispatch("chat/searchUsers", { accounts });

      const res = await team.createTeam(userlist);
      const obj = {};
      obj.sessionId = Utils.getSessionId(this.$store.state.chat.userId, res.sessionId);
      obj.scene = userlist.length > 1 ? "team" : "p2p";
      this.$router.push({
        path: "/chat",
        query: { id: `${obj.scene}-${obj.sessionId}`, unread: 0 }
      });
    },
    @request(true, "enLoading", {
      subTitle: "正在加载中..."
    })
    async loadData() {
      if (this.idType === "payRollId") { // 邮箱查看
        this.payRollDetail = await payRollApi.queryPaySlipsDetail(this.id);
      } else {
        const paySlipList = await payRollApi.queryPaySlipList(this.leftData.id, undefined, undefined, 1);
        if (paySlipList.length > 0) {
          this.payRollDetail = paySlipList[0];
        }
      }
      // 工资条详情数据不为空
      if (this.payRollDetail) {
        const resp = await payRollApi.queryPaySlipsColList(this.payRollDetail.templateId);
        // 工资条的表头字段集合
        this.colList = resp.colList;
        // 标识页面有数据
        this.$set(this.param, "hasData", true);
        const lockStatus = this.payRollDetail.lockStatus;
        if (lockStatus === 1) {
          // 值为1代表上锁了。弹框
        } else {
          if (this.idType === "payRollId") {
            // 邮箱查看
            this.payDate = `${this.payRollDetail.payYear} 年 ${this.payRollDetail.payMonth}  月工资条`;
          } else {
            this.payDate = `${this.payRollDetail.payMonth} 月工资条`;
          }
          // 工资条配置的字段关联关系
          const refInfo = this.payRollDetail.refInfo;
          // 关联的名称字段
          const nameKey = refInfo.nameKey;
          // 关联的账号id字段
          const enAccountId = refInfo.enAccountId;
          // 去掉姓名字段（姓名不在表格内显示）
          this.colList = this.colList.filter((element) => element.fieldName !== nameKey);
          // 获得工资条的姓名
          this.userName = this.payRollDetail[nameKey];
          // 获得手签保存的图片地址
          if (this.payRollDetail.signUrl) {
            if (this.payRollDetail.signUrl.startsWith("data:image/png;base64,")) {
              this.param.signUrl = this.payRollDetail.signUrl;
            } else {
              this.param.signUrl = `data:image/png;base64,${this.payRollDetail.signUrl}`;
            }
          }
          // 是否有手签控件 0-有 1-无
          const isSign = this.payRollDetail.isSign;
          // 判断是否可以展示手签控件，本人才可以展示手签
          if (isSign === 0 && enAccountId === this.$store.getters.userInfo.userId) {
            this.$set(this.param, "hasSign", true);
            // 如果已手签展示手签的图片,没有手签展示手签控件
            if (this.param.signUrl) {
              this.$set(this.param, "isSaved", true);
            } else {
              this.$set(this.param, "isSaved", false);
            }
          } else {
            this.$set(this.param, "hasSign", false);
            this.$set(this.param, "isSaved", false);
          }
        }
      } else {
        // 显示无数据
        this.$set(this.param, "hasData", false);
      }
      this.$nextTick(() => {
        this.initPage();
      });
    },
    // 重签
    toClear() {
      this.ctx.clearRect(0, 0, 300, 100);
    },
    /**
     * @description: 判断是是否已经签名
     * @param {*} canvas
     * @return {*}
     */
    isCanvasBlank(canvas) {
      const blank = document.createElement("canvas");
      blank.width = canvas.width;
      blank.height = canvas.height;
      const ctx = blank.getContext("2d");
      const background = "#fff";
      ctx.lineCap = "round";
      ctx.fillStyle = background;
      ctx.lineWidth = 2;
      ctx.fillRect(0, 0, 350, 150);
      return canvas.toDataURL() === blank.toDataURL();// 比较值相等则为空
    },
    // 保存
    async toSave() {
      if (this.isCanvasBlank(this.canvas)) {
        this.$message.error("请签名");
        return;
      }
      let base64Img = this.canvas.toDataURL();
      const startImag = "data:image/png;base64,";
      if (base64Img.startsWith(startImag)) {
        base64Img = base64Img.substr(startImag.length);
      }
      await payRollApi.savePaySlip(this.payRollDetail.id, this.payRollDetail.templateId, base64Img);
      // 隐藏手签与签收按钮
      this.$set(this.param, "isSaved", true);
      this.$set(this.param, "signUrl", startImag + base64Img);
    },
    linkup() {
      this.showaddChats = true;
      this.$nextTick(() => {
        this.$refs.addPerson.open();
      });
    },
    getCloseLinkup(val) {
      this.showaddChats = val;
    }
  }
};
</script>
<style lang="scss">
.top-nav-right {
  .text {
    font-size: 14px !important;
  }
}
</style>

<style lang="scss" scoped>
.paystub-right {
  background: #fff;
  margin-left: 292px;
  height: 100%;
  border-radius: 5px;
  text-align: left;
  position: relative;
  overflow: auto;
  .flex-wrap{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .top-nav {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #eee;
    .top-nav-left {
      float: left;
      margin: 15px 0 0 20px;
      span {
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
        margin: 3px 0 0 3px;
        font-weight: bold;
        color: #333;
      }
    }
    .top-nav-right {
      float: right;
      margin: 15px 15px 0 0;
    }
  }
  .user-nav {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #eee;
    background-color: #f5f7fa;
    font-size: 14px;
    margin-bottom: 10px;
    overflow-y: auto;
    .name-content {
      display: inline-block;
      margin: 30px 365px 0 23px;
      span {
        margin-left: 10px;
        color: #666;
      }
    }
    .money-content {
      display: inline-block;
      i {
        font-style: normal;
        font-size: 18px;
        color: #3e90fe;
      }
    }
  }
  .info-table {
    flex: 1;
    overflow-y: auto;
    table {
      border: 1px solid #eee;
      border-collapse: collapse;
      width: 100%;
      font-size: 14px;
      tr {
        td {
          border: 1px solid #eee;
          border-collapse: collapse;
          padding: 16px 0 16px 12px;
        }
        td:nth-child(1) {
          color: #333;
        }
        td:nth-child(2) {
          color: #666;
        }
      }
      tr:nth-child(odd) {
        background-color: #f7f9f9;
      }
      tr:last-child {
        background-color: #fff;
        td:nth-child(2) {
          position: relative;
          .againSign {
            position: absolute;
            top: 20px;
            right: 30px;
            color: #3e90fe;
            cursor: pointer;
          }
        }
      }
    }
  }
  .bottom-nav {
    width: 100%;
    height: 65px;
    border-top: 1px solid #eee;
    .el-button {
      float: right;
      margin: 20px 20px 0 0;
    }
  }
}
canvas {
  border: 2px dashed #cccccc;
}
.no-data {
  background: white;
  height: 100%;
}
</style>
